import { Skeleton } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
const SideLinks = (props: any) => {
  const { links, loading, loaded } = props;
  return (
    <div className="page-side-nav">
      <ul className="about-nav">
        {loading && (
          <Skeleton
            sx={{ borderRadius: "4px" }}
            variant="rectangular"
            width={"100%"}
            height={380}
          />
        )}
        {loaded && (
          <>
            {links.map((item: any) => (
              <li key={item.path}>
                <Link to={item.path}>{item.title}</Link>
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
};

export default SideLinks;
