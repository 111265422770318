import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  Slide,
  Toolbar,
  AppBar,
  Divider,
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  Dialog,
} from "@mui/material";
import { Done, InfoOutlined } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ConfirmModal = (props: any) => {
  const val = props.data;
  const createMarkup = (text: any) => {
    return { __html: text };
  };
  return (
    <>
      <Dialog
        onClose={val.onclose}
        open={val.onopen}
        aria-labelledby={"Me"}
        TransitionComponent={Transition}
      >
        <div className="alert-container">
          <div className={`head-area info-bg bg-info`}>
            <i className={`fas fa-info-circle`}></i>
          </div>
          <div className={`message-area color-info`}>
            {val.message && (
              <div
                className="text-center"
                dangerouslySetInnerHTML={createMarkup(val.message)}
              />
            )}
            {val.loading_message && (
              <div
                className="text-center"
                dangerouslySetInnerHTML={createMarkup(val.loading_message)}
              />
            )}
          </div>
          <div className="button-area">
            <div className="flex align-items-center">
              <span className="spacer">
                <Button
                  fullWidth
                  size="large"
                  onClick={val.onclose}
                  sx={{ paddingY: "13px", color: "orange" }}
                >
                  <span className="px10"> EXIT</span>
                </Button>
              </span>
              <Divider orientation="vertical" />
              <span className="spacer">
                <Button
                  fullWidth
                  disabled={val.loading}
                  onClick={() => val.onaccept(val)}
                  sx={{ paddingY: "13px", color: "green" }}
                >
                  <Done /> {val.loading ? "Working...." : "Yes, Proceed"}
                </Button>
              </span>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default React.memo(ConfirmModal);
