import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, Card, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import useFetchKindreds from "../../../hooks/useFetchKindreds";
import useFetchQuaters from "../../../hooks/useFetchQuaters";
import useFetchLocationTypes from "../../../hooks/useFetchLocationTypes";
import CustomModal from "../../templates/CustomModal";

const EditMember = () => {
  const params = useParams();
  const isParam = params.mId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [member, setMember] = React.useState<any>({ designation: "0" });

  const [email, setEmail] = React.useState<any>("");
  const [phone, setPhone] = React.useState<any>("");
  const [surname, setSurname] = React.useState<any>("");
  const [firstname, setFirstname] = React.useState<any>("");
  const [othernames, setOthernames] = React.useState<any>("");
  const [kindred_id, setKindredId] = React.useState<any>("");
  const [quarter_id, setQuarterId] = React.useState<any>("");
  const [location_type_id, setLocationTypeId] = React.useState<any>("");
  const [bio, setBio] = React.useState<any>("");
  const [role, setRole] = React.useState<any>("");
  let [id, setId] = React.useState<any>(0);

  const { kindreds, loading_kindreds, loaded_kindreds } = useFetchKindreds();
  const { quaters, loading_quaters, loaded_quaters } = useFetchQuaters();
  const { location_types, loading_location_types, loaded_location_types } =
    useFetchLocationTypes();

  React.useEffect(() => {
    getMember(params.mId);
  }, [params?.mId]);

  const getMember = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ id: id }, "member_details")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setMember(result.data);
            setEmail(result.data.email);
            setPhone(result.data.phone);
            setSurname(result.data.surname);
            setFirstname(result.data.firstname);
            setOthernames(result.data.othernames);
            setKindredId(result.data.kindred_id);
            setQuarterId(result.data.quarter_id);
            setLocationTypeId(result.data.location_type_id);
            setRole(result.data.role);
            setPhone(result.data.phone);
            setBio(result.data.bio);
            setId(result.data.id);
            setPreview(process.env.REACT_APP_SERVER_ROOT + result.data.picture);
          } else {
            setMember({});
          }
        },
        (error) => {
          setMember({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const onHtmlChange = (e: any) => {
    setBio(e.target.value);
  };

  const handleInputChange = React.useCallback(
    (e: any) => {
      console.log(e.target);
      const name = e.target.name;
      setMember({ ...member, [name]: e.target.value });
    },
    [member]
  );

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New Member",
  });

  const handleSubmit = () => {
    console.log("SUBMITTING");
    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("page", "admin");
    formData.append("id", id);
    formData.append("email", email);
    formData.append("phone", phone);
    formData.append("surname", surname);
    formData.append("firstname", firstname);
    formData.append("othernames", othernames);
    formData.append("kindred_id", kindred_id);
    formData.append("quarter_id", quarter_id);
    formData.append("location_type_id", location_type_id);
    formData.append("banner", file);
    formData.append("bio", bio);
    formData.append("role", role);

    HttpService.postFormHeader("edit_member", formData)
      .then((resp) => {
        console.log(resp);
        const severity =
          resp.status === 1
            ? "success"
            : resp.status === 0
            ? "error"
            : resp.status === 44
            ? "error"
            : "info";
        setModal({
          ...modal,
          onopen: true,
          message: resp.message,
          severity: severity,
        });
        if (resp.status === 1) {
        }
      })
      .catch((err) => {
        console.log(err);
        setModal({
          ...modal,
          onopen: true,
          severity: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/members">Members</Link>
            <Link to={`/admin/members/p/${member?.id}`}>{member?.name}</Link>
          </Breadcrumbs>
          <h1>Edit {member?.name}</h1>
        </div>
        <section className="page-detail-container">
          <div className="py30 px20">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={`input mb0 pr5 spacer ${loading ? "iconed" : ""}`}
                >
                  <label>Surname</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="surname"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    placeholder={"Surname "}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={`input mb0 spacer pr5 ${loading ? "iconed " : ""}`}
                >
                  <label>Firstname</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="firstname"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    placeholder={"Firstname "}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Othernames</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="othernames"
                    value={othernames}
                    onChange={(e) => setOthernames(e.target.value)}
                    placeholder={"Othernames"}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <div className={`input mb0 pr5 spacer`}>
                  <label className="">
                    {"Kindred (Umunna)"}
                    <sup
                      className="boldest red"
                      title="This field is required!"
                    >
                      *
                    </sup>
                  </label>
                  <select
                    className={" input-form-control "}
                    name={"kindred_id"}
                    required
                    placeholder="Select Kindred (Umunna)"
                    value={kindred_id}
                    onChange={(e) => setKindredId(e.target.value)}
                  >
                    <option value="">Select Kindred...</option>
                    {kindreds.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={`input mb0 pr5 spacer`}>
                  <label className="textfeld-control">
                    {"Quarter"}
                    <sup
                      className="boldest red"
                      title="This field is required!"
                    >
                      *
                    </sup>
                  </label>
                  <select
                    className={" input-form-control "}
                    name={"quarter_id"}
                    required
                    placeholder="Quarter"
                    value={quarter_id}
                    onChange={(e) => setQuarterId(e.target.value)}
                  >
                    <option value="">Select Quarter</option>
                    {quaters.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={`input mb0 spacer`}>
                  <label className="textfeld-control">
                    {"Residence Type"}
                    <sup
                      className="boldest red"
                      title="This field is required!"
                    >
                      *
                    </sup>
                  </label>
                  <select
                    className={" input-form-control "}
                    name={"location_type_id"}
                    required
                    placeholder="Residence Type"
                    value={location_type_id}
                    onChange={(e) => setLocationTypeId(e.target.value)}
                  >
                    <option value="">Select Residence Type...</option>
                    {location_types.map((item) => (
                      <option value={item.id} key={item.title}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={4}>
                <div
                  className={`input mb0 pr5 spacer ${loading ? "iconed" : ""}`}
                >
                  <label>Email Address</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder={"Email Address"}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Phone</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder={"Phone "}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <div className={`input mb0 spacer`}>
                  <label className="textfeld-control">{"User Privilege"}</label>
                  <select
                    className={" input-form-control "}
                    name={"role"}
                    required
                    placeholder="User Privilege"
                    value={role}
                    onChange={(e) => setRole(e.target.value)}
                  >
                    <option value="">Select User Privilege...</option>
                    <option value="admin">Admin Member</option>
                    <option value="">Regular Member</option>
                  </select>
                </div>
              </Grid>
            </Grid>

            <div className="banner-section">
              {preview_image && (
                <div className="image_preview">
                  <img className="" src={preview_image} alt="preview Image" />
                </div>
              )}
              <div
                className={
                  loading
                    ? " input mb0 iconed mt20 pt10"
                    : " input mb0 mt20 pt10"
                }
              >
                <label>Attach Member's Picture</label>
                <input
                  type="file"
                  className="form-control"
                  name="file"
                  onChange={handleFileChange}
                  placeholder={"picture"}
                />
              </div>
            </div>

            <div className="mb10">
              <DefaultEditor
                className="form-control"
                placeholder="Member's Biography"
                value={bio}
                onChange={onHtmlChange}
              />
            </div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Working..." : " Add Member"}
            </Button>
          </div>
        </section>
      </Card>
      {modal.onopen && <CustomModal data={modal} />}
    </div>
  );
};

export default EditMember;
