import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, Card, Grid } from "@mui/material";
import HttpService from "../../../services/HttpService";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import CustomModal from "../../templates/CustomModal";
import DurationPipe from "../../../pipes/DurationPipe";
import Currency from "../../../pipes/Currency";
import {
  AddModerator,
  CameraAltOutlined,
  CommentOutlined,
  DirectionsOutlined,
  MoneyOutlined,
  Timer,
  TimerOutlined,
  VideoCameraFrontOutlined,
} from "@mui/icons-material";

import PlaceHolder from "../../templates/PlaceHolder";
import DatePipe from "../../../pipes/DatePipe";

const LevyDetail = () => {
  const params = useParams();
  const isParam = params.mId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [levy, setLevy] = React.useState<any>({ designation: "0" });

  const [preview, setPreview] = React.useState<any>("");
  const [title, setTitle] = React.useState<any>("");
  const [venue, setVenue] = React.useState<any>("");
  const [start_time, setStartTime] = React.useState<any>("");
  const [end_time, setEndTime] = React.useState<any>("");
  const [description, setDesc] = React.useState<any>("");
  let [id, setId] = React.useState<any>(0);

  React.useEffect(() => {
    getlevy(params.mId);
  }, [params?.mId]);

  const getlevy = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ id: id }, "levy_details")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setLevy(result.data);
            setTitle(result.data.title);
            setVenue(result.data.venue);
            setStartTime(result.data.start_time);
            setEndTime(result.data.end_time);
            setDesc(result.data.description);
            setId(result.data.id);
            setPreview(process.env.REACT_APP_SERVER_ROOT + result.data.picture);
          } else {
            setLevy({});
          }
        },
        (error) => {
          setLevy({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New levy",
  });

  const [value, setValue] = React.useState("one");

  const handleChange = (levy: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <section className="page-detail-container">
      <section className="container py20">
        <Card sx={{ borderRadius: "0" }}>
          {loaded && (
            <Card sx={{ borderRadius: "0" }}>
              <div className="page-head bga">
                <div className="flex flex-row-resp align-items-center">
                  <div className="inline-block pxy20 spacer">
                    <Breadcrumbs
                      aria-label="breadcrumb"
                      sx={{
                        width: "100%",
                      }}
                    >
                      <Link to="/admin/dashbaord">Dashbaord</Link>
                      <Link to="/admin/levies">Levies</Link>
                    </Breadcrumbs>
                    <h2 className="mt10">{levy.title}</h2>
                    <div>
                      <i className="fas fa-timer"></i>
                      <DatePipe value={levy.due_date * 1000} />
                    </div>
                  </div>

                  <span className="px20 sm-hide-inline">
                    <Avatar
                      variant="circular"
                      sx={{
                        width: 120,
                        height: 120,
                      }}
                      alt={`${levy.title} `}
                      src={
                        process.env.REACT_APP_SERVER_FILES_DOMAIN + levy.thumb
                      }
                    />
                  </span>
                </div>
              </div>

              <div className="pxy0 bg-differ">
                <Divider />
                <Card sx={{ m: "0", p: "0" }}>
                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 0, sm: 2, md: 2 }}
                    sx={{
                      display: "flex",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <ListItem divider>
                        <ListItemIcon>
                          <MoneyOutlined sx={{ fontSize: "40px" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <div className="bolder">Amount</div>
                          <div className="">
                            <Currency value={levy.amount} />
                          </div>
                        </ListItemText>
                      </ListItem>
                    </Grid>

                    <Grid item xs={12} sm={6} md={6}>
                      <ListItem divider>
                        <ListItemIcon>
                          <TimerOutlined sx={{ fontSize: "40px" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <div className="bolder">Due Date</div>
                          <div>
                            <DatePipe value={levy.due_date * 1000} />
                          </div>
                        </ListItemText>
                      </ListItem>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    rowSpacing={0}
                    columnSpacing={{ xs: 0, sm: 2, md: 2 }}
                    sx={{
                      display: "flex",
                      backgroundColor: "#fafafa",
                    }}
                  >
                    <Grid item xs={12} sm={6} md={6}>
                      <ListItem divider>
                        <ListItemIcon>
                          <TimerOutlined sx={{ fontSize: "40px" }} />
                        </ListItemIcon>
                        <ListItemText>
                          <div className="bolder">Event Duration</div>
                          <div>
                            <DurationPipe
                              start={levy.start_date * 1000}
                              end={levy.end_date * 1000}
                            />
                          </div>
                        </ListItemText>
                      </ListItem>
                    </Grid>
                  </Grid>
                </Card>
              </div>
            </Card>
          )}
          {loading && <PlaceHolder type="levy-detail" />}
        </Card>
      </section>
    </section>
  );
};

export default LevyDetail;
