import React from "react";
import { Routes, Route } from "react-router-dom";
import MeetingDetail from "./MeetingDetail";
import MeetingsList from "./MeetingsList";
const Meetings = () => {
  return (
    <React.Fragment>
      <section className="bg-diff">
        <Routes>
          <Route path="" element={<MeetingsList />} />
          <Route path="/" element={<MeetingsList />} />
          <Route path="/e/:url" element={<MeetingDetail />} />
        </Routes>
      </section>
    </React.Fragment>
  );
};

export default Meetings;
