import React from "react";
import { Routes, Route } from "react-router-dom";
import CasesList from "./CasesList";
import CaseDetail from "./CaseDetail";
import EditCase from "./EditCase";
import CreateCase from "./CreateCase";

const Cases = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<CasesList />} />
        <Route path="/" element={<CasesList />} />
        <Route path="/p/:mId" element={<CaseDetail />} />
        <Route path="/e/:mId" element={<EditCase />} />
        <Route path="/a/new" element={<CreateCase />} />
      </Routes>
    </React.Fragment>
  );
};

export default Cases;
