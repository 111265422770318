import React from "react";
import { Link } from "react-router-dom";
import PlaceHolder from "../../templates/PlaceHolder";
import Button from "@mui/material/Button";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import EventsListTemplate from "../../templates/EventsListTemplate";
import Header from "../../Header/Header";
import PageIntro from "../PageIntro/PageIntro";
import Footer from "../../Footer/Footer";
import { Card } from "@mui/material";
import EventsLayout from "./EventsLayout";

const EventList = (props: any) => {
  const [offset, setOffset] = React.useState<number>(0);
  const [events, setEvent] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [page_data, setPageDate] = React.useState<any>({});

  React.useEffect(() => {
    setPageDate({
      ...page_data,
      page_slug: "",
      page_title: "All Events",
      page_summary: "All Dianaokwu  Events",
    });
    listEvent(offset);
  }, []);

  const listEvent = (offset: number) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ offset: offset, limit: 24, mode: "all" }, "all_events")
      .then(
        (result) => {
          console.log(result);
          if (result.status === 1) {
            let newRes = [...events, ...result.data];
            setEvent(result.data);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const loadMore = () => {
    const newOffset = offset + 20;
    setOffset(newOffset);
    listEvent(newOffset);
  };

  return (
    <React.Fragment>
      <EventsLayout page_data={page_data}>
        {loaded && (
          <EventsListTemplate
            loading={loading}
            loaded={loaded}
            events={events}
            max_grid={4}
          />
        )}
        {loading && <PlaceHolder type="articles" />}
        <div className="flex justify-content-center align-items-center py20">
          <button disabled={loading} className="more" onClick={loadMore}>
            {loading ? "Loading..." : "Load More"}
          </button>
        </div>
      </EventsLayout>
    </React.Fragment>
  );
};

export default EventList;
