import React from "react";
import { Routes, Route } from "react-router-dom";
import ListKindreds from "./ListKindreds";
import EditKindred from "./EditKindred";
import CreateKindred from "./CreateKindred";
import KindredDetails from "./KindredDetails";

const Kindreds = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<ListKindreds />} />
        <Route path="/" element={<ListKindreds />} />
        <Route path="/e/:kid" element={<EditKindred />} />
        <Route path="/a/new" element={<CreateKindred />} />
        <Route path="/p/:kid" element={<KindredDetails />} />
      </Routes>
    </React.Fragment>
  );
};

export default Kindreds;
