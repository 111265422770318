import React from "react";
import HttpService from "../services/HttpService";

const useFetchMemberGroups = () => {
  const [member_groups, setMemberGroup] = React.useState<any[]>([]);
  const [loading_member_groups, setLoading] = React.useState(false);
  const [loaded_member_groups, setLoaded] = React.useState(false);

  React.useEffect(() => {
    fetch_member_groups();
  }, []);

  const fetch_member_groups = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("member_groups", {})
      .then(
        (res) => {
          if (res.status === 1) {
            setMemberGroup(res.data);
          }
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return {
    member_groups,
    loading_member_groups,
    loaded_member_groups,
  };
};

export default useFetchMemberGroups;
