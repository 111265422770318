import React from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import AuthService from "../../services/AuthService";
import "./Header.css";
import useFetchEventCategories from "../../hooks/useFetchEventCategories";
import { Tooltip } from "@mui/material";
const Header = (props: any) => {
  const { base_page } = props;
  const [isTogged, setIsTogged] = React.useState(false);
  const page = useLocation().pathname;
  const logx = AuthService.isLogged();
  const usr = AuthService.getCurrentUser();
  const [isLogged, setIsLogged] = React.useState(logx);
  const navigate = useNavigate();

  const doLogout = () => {
    AuthService.logout();
    setTimeout(() => {
      console.log("Session Cleared...");
      setIsLogged(false);
      return navigate("/");
    }, 300);
  };

  const closeNav = () => {
    setNavData({ ...nav_data, onopen: false });
  };
  const [nav_data, setNavData] = React.useState<any>({
    ononpen: false,
    onclose: closeNav,
    isLogged: isLogged,
  });
  const toggleNav = () => {
    setNavData({
      ...nav_data,
      isLogged: isLogged,
      onopen: true,
      onclose: closeNav,
    });
    setIsTogged((cur) => !cur);
  };

  const doBoth = () => {
    doLogout();
    toggleNav();
  };

  const showMobileNav: boolean = isTogged ? true : false;
  const {
    event_categories,
    loading_event_categories,
    loaded_event_categories,
  } = useFetchEventCategories();
  return (
    <React.Fragment>
      <header
        className={`main-header ${base_page === "home" ? "home-header" : ""}`}
      >
        <div className="logo">
          <Link to="/">
            <img src="/images/logo2.png" alt="Logo" />
          </Link>
        </div>
        <span className="spacer"></span>

        <div className="dektop-nav">
          <ul className="header-navigation">
            <li className="nav-more-icon">
              <Link to="/about">About Us</Link>
              <ul>
                <li>
                  <Link to="/about/history">History</Link>
                </li>
                <li>
                  <Link to="/about/heritage">Heritage</Link>
                </li>
                <li>
                  <Link to="/about/quarters">Quarters</Link>
                </li>
                <li>
                  <Link to="/about/leaders">Leaders</Link>
                </li>
                <li>
                  <Link to="/about/ebo-ani">Ichie Eboani</Link>
                </li>
                <li>
                  <Link to="/about/committees">Committees</Link>
                </li>
              </ul>
            </li>
            <li>
              <Link to="/kindreds">Kindreds</Link>
            </li>
            <li>
              <Link to="/branches">Branches</Link>
            </li>
            <li className={loaded_event_categories ? "nav-more-icon" : ""}>
              <Link to="/events">Events</Link>

              {loaded_event_categories && event_categories && (
                <ul>
                  {event_categories.map((item, index) => (
                    <li key={item.title}>
                      <Link to={`/events/${item?.slug}`}>{item?.title}</Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
            <li className="nav-more-icon">
              <Link to="/gallery">Gallery</Link>
              <ul>
                <li>
                  <Link to="/gallery/videos">Videos</Link>
                </li>
                <li>
                  <Link to="/gallery/audios">Audios</Link>
                </li>
                <li>
                  <Link to="/gallery/letters">Letters</Link>
                </li>
              </ul>
            </li>
          </ul>
          <span className="header-cta">
            {!isLogged && (
              <>
                <Tooltip title="Log In">
                  <Link to="/login" className="login">
                    LOGIN
                  </Link>
                </Tooltip>
                <Tooltip title="Join Now">
                  <Link to="/register" className="register">
                    JOIN NOW
                  </Link>
                </Tooltip>
              </>
            )}
            {isLogged && (
              <>
                <Tooltip title="Dashboard">
                  <Link
                    to={
                      usr.role === "admin"
                        ? "/admin/dashboard"
                        : "/account/dashboard"
                    }
                    className="register"
                  >
                    <i className="fas fa-dashboard"></i>
                  </Link>
                </Tooltip>
                <Tooltip title="Log Out">
                  <Link to="#" onClick={doLogout} className="logout">
                    <i className="fas fa-sign-out-alt"></i>
                  </Link>
                </Tooltip>
              </>
            )}
          </span>
        </div>
        <span className="mobile-nav">
          <button className="mnav">
            <i className="fas fa-bars"></i>
          </button>
        </span>
      </header>
    </React.Fragment>
  );
};

export default Header;
