import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, Card, Grid, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import dayjs, { Dayjs } from "dayjs";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import useFetchEventCategories from "../../../hooks/useFetchEventCategories";

const EditEvent = () => {
  const params = useParams();
  const isParam = params.mId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [event, setEvent] = React.useState<any>({ designation: "0" });

  const [title, setTitle] = React.useState<any>("");
  const [location, setLocation] = React.useState<any>("");
  const [start_time, setStartTime] = React.useState<any>("");
  const [end_time, setEndTime] = React.useState<any>("");
  const [description, setDesc] = React.useState<any>("");
  const [host_type, setHostType] = React.useState<any>("");
  let [id, setId] = React.useState<any>(0);
  let [category_id, setCategoryId] = React.useState<any>("");

  React.useEffect(() => {
    getevent(params.mId);
  }, [params?.mId]);

  const getevent = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ id: id }, "event_details")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setEvent(result.data);
            setCategoryId(result.data.category_id);
            setTitle(result.data.title);
            setLocation(result.data.location);
            setHostType(result.data.host_type);
            setStartTime(new Date(result.data.start_time * 1000).toUTCString());
            setEndTime(new Date(result.data.end_time * 1000).toUTCString());
            setDesc(result.data.description);
            setId(result.data.id);
            setPreview(process.env.REACT_APP_SERVER_ROOT + result.data.picture);
          } else {
            setEvent({});
          }
        },
        (error) => {
          setEvent({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const {
    event_categories,
    loading_event_categories,
    loaded_event_categories,
  } = useFetchEventCategories();

  const onHtmlChange = (e: any) => {
    setDesc(e.target.value);
  };

  const handleInputChange = React.useCallback(
    (e: any) => {
      console.log(e.target);
      const name = e.target.title;
      setEvent({ ...event, [name]: e.target.value });
    },
    [event]
  );

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.title;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New event",
  });

  const handleSubmit = () => {
    console.log("SUBMITTING");
    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("page", "admin");
    formData.append("id", id);
    formData.append("category_id", category_id);
    formData.append("host_type", host_type);
    formData.append("title", title);
    formData.append("location", location);
    formData.append("start_time", start_time);
    formData.append("end_time", end_time);
    formData.append("banner", file);
    formData.append("description", description);
    console.log(formData);
    HttpService.postFormHeader("edit_event", formData)
      .then((resp) => {
        console.log(resp);
        const severity =
          resp.status === 1
            ? "success"
            : resp.status === 0
            ? "error"
            : resp.status === 44
            ? "error"
            : "info";
        setModal({
          ...modal,
          onopen: true,
          message: resp.message,
          severity: severity,
        });
        if (resp.status === 1) {
        }
      })
      .catch((err) => {
        console.log(err);
        setModal({
          ...modal,
          onopen: true,
          severity: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/events">Events</Link>
            <span>Edit {event?.title}</span>
          </Breadcrumbs>
          <h1>Edit {event?.title}</h1>
        </div>
        <section className="page-detail-container">
          <div className="py30 px20">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <label>Event Title</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={"Event Title"}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Event Location</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="location"
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                    placeholder={"Event Location "}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                      <DateTimePicker
                        label="Start Time"
                        value={start_time}
                        onChange={(e) => setStartTime(e?.toISOString())}
                        renderInput={(params: any) => (
                          <TextField className="flex spacer" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div
                  className={`input mb0 spacer  ${loading ? "iconed " : ""}`}
                >
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                      <DateTimePicker
                        label="End Time"
                        value={end_time}
                        onChange={(e) => setEndTime(e?.toISOString())}
                        renderInput={(params: any) => (
                          <TextField className="flex spacer" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer`}>
                  <label className="textfeld-control">
                    {"Event Category"}
                    <sup
                      className="boldest red"
                      title="This field is required!"
                    >
                      *
                    </sup>
                  </label>
                  <select
                    className={" input-form-control "}
                    name={"category_id"}
                    required
                    placeholder="Event Category"
                    value={category_id}
                    onChange={(e) => setCategoryId(e.target.value)}
                  >
                    <option value="">Select Event Category...</option>
                    {event_categories.map((item) => (
                      <option value={item.id} key={item.title}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer`}>
                  <label className="textfeld-control">
                    {"Event Host Type"}
                    <sup
                      className="boldest red"
                      title="This field is required!"
                    >
                      *
                    </sup>
                  </label>
                  <select
                    className={" input-form-control "}
                    name={"host_type"}
                    required
                    placeholder="Host Type"
                    value={host_type}
                    onChange={(e) => setHostType(e.target.value)}
                  >
                    <option value="">Select Host Type...</option>
                    <option value="village">Village</option>
                    <option value="member">Member</option>
                  </select>
                </div>
              </Grid>
            </Grid>

            <div className="banner-section">
              {preview_image && (
                <div className="image_preview">
                  <img className="" src={preview_image} alt="preview Image" />
                </div>
              )}
              <div
                className={
                  loading
                    ? " input mb0 iconed mt20 pt10"
                    : " input mb0 mt20 pt10"
                }
              >
                <label>Attach event's Banner</label>
                <input
                  type="file"
                  className="form-control"
                  name="file"
                  onChange={handleFileChange}
                  placeholder={"picture"}
                />
              </div>
            </div>

            <div className="mb10">
              <DefaultEditor
                className="form-control"
                placeholder="event's descriptiongraphy"
                value={description}
                onChange={onHtmlChange}
              />
            </div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Working..." : " Add event"}
            </Button>
          </div>
        </section>
      </Card>
      {modal.onopen && <CustomModal data={modal} />}
    </div>
  );
};

export default EditEvent;
