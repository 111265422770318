import React from "react";
import { Routes, Route } from "react-router-dom";
import CreateEvent from "./CreateEvent";
import EditEvent from "./EditEvent";
import EventsList from "./EventsList";
import EventDetail from "./EventDetail";
import CreateCategory from "./CreateCategory";

const Events = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<EventsList />} />
        <Route path="/" element={<EventsList />} />
        <Route path="/p/:mId" element={<EventDetail />} />
        <Route path="/e/:mId" element={<EditEvent />} />
        <Route path="/a/new" element={<CreateEvent />} />
        <Route path="/c/new" element={<CreateCategory />} />
      </Routes>
    </React.Fragment>
  );
};

export default Events;
