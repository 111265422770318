import React from "react";
import HttpService from "../services/HttpService";

const useFetchNonIndigeneCategories = () => {
  const [non_indigene_categories, setCategories] = React.useState<any[]>([]);
  const [loading_non_indigene_categories, setLoading] = React.useState(false);
  const [loaded_non_indigene_categories, setLoaded] = React.useState(false);

  React.useEffect(() => {
    fetch_kindred();
  }, []);

  const fetch_kindred = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("non_indigene_categories", {})
      .then(
        (res) => {
          if (res.status === 1) {
            setCategories(res.data);
          }
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return {
    non_indigene_categories,
    loading_non_indigene_categories,
    loaded_non_indigene_categories,
  };
};

export default useFetchNonIndigeneCategories;
