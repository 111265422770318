import React from "react";
import HttpService from "../services/HttpService";

const useFetchLevyCategories = () => {
  const [levy_categories, setCategories] = React.useState<any[]>([]);
  const [loading_levy_categories, setLoading] = React.useState(false);
  const [loaded_levy_categories, setLoaded] = React.useState(false);

  React.useEffect(() => {
    fetch_levy_category();
  }, []);

  const fetch_levy_category = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("levy_categories", {})
      .then(
        (res) => {
          if (res.status === 1) {
            setCategories(res.data);
          }
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return {
    levy_categories,
    loading_levy_categories,
    loaded_levy_categories,
  };
};

export default useFetchLevyCategories;
