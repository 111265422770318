import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import * as processHtml from "../../../services/processHtml";
import HttpService from "../../../services/HttpService";
import Collapse from "@mui/material/Collapse";
import PlaceHolder from "../../templates/PlaceHolder";
import { Card } from "@mui/material";
const FaqPane = () => {
  const { decodeHtml, truncateWord } = processHtml;
  const [faqs, setFaq] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);

  React.useEffect(() => {
    listfaqs();
  }, []);

  const listfaqs = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("list_faq", {})
      .then(
        (result) => {
          console.log(result);
          if (Array.isArray(result.data)) {
            setFaq(result.data);
          }
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const togView = (index: number, state: any) => {
    let mutd: any[] = [...faqs];
    mutd[index]["is_togged"] = !mutd[index]["is_togged"];
    setFaq(mutd);
  };
  return (
    <React.Fragment>
      <Card sx={{}}>
        {faqs.map((item: any, index: number) => (
          <ListItem
            disablePadding
            key={index}
            divider={index < faqs.length - 1 ? true : false}
            component={"section"}
          >
            <ListItemButton
              disableRipple
              onClick={() => togView(index, !item?.is_togged)}
            >
              <ListItemText
                disableTypography
                primary={
                  <div className="flex flex-row align-items-center">
                    <strong
                      className="spacer"
                      style={{ lineHeight: "1.2", fontSize: "19px" }}
                    >
                      {item?.question}
                    </strong>
                    <span>
                      <i
                        className={`fas ${
                          item.is_togged ? "fa-chevron-up" : "fa-chevron-down"
                        }`}
                      ></i>
                    </span>
                  </div>
                }
                secondary={
                  <Collapse in={item?.is_togged}>
                    <div
                      className="properties-summary"
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(item.answer),
                      }}
                    ></div>
                  </Collapse>
                }
              ></ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
      </Card>
      {loading && <PlaceHolder type="horizontal_list" />}
    </React.Fragment>
  );
};

export default FaqPane;
