import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs, Card, Grid, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import dayjs, { Dayjs } from "dayjs";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import useFetchNonIndigeneCategories from "../../../hooks/useFetchNonIndigeneCategories";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

const NonIndigeneNew = () => {
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [non_indigene, setNonIndigene] = React.useState<any>({
    designation: "0",
  });
  let [description, setContent] = React.useState<any>("");

  const dts = new Date();
  React.useEffect(() => {
    setNonIndigene({ ...non_indigene, movein_date: dts });
  }, []);
  const handleInputChange = React.useCallback(
    (e: any) => {
      const name = e.target.name;
      setNonIndigene({ ...non_indigene, [name]: e.target.value });
    },
    [non_indigene]
  );

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New non indigene",
  });
  const onHtmlChange = (e: any) => {
    setContent(e.target.value);
    console.log(description);
  };

  const handleSubmit = () => {
    console.log("SUBMITTING:: ", non_indigene);

    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("page", "admin");
    formData.append("description", non_indigene?.description);
    formData.append("surname", non_indigene?.surname);
    formData.append("firstname", non_indigene?.firstname);
    formData.append("othernames", non_indigene?.othernames);
    formData.append("hometown", non_indigene?.hometown);
    formData.append("category_id", non_indigene?.category_id);
    formData.append("phone", non_indigene?.phone);
    formData.append("email", non_indigene?.email);
    formData.append("nationality", non_indigene?.nationality);
    formData.append(
      "previous_residential_address",
      non_indigene?.previous_residential_address
    );
    formData.append(
      "previous_residential_town",
      non_indigene?.previous_residential_town
    );
    formData.append(
      "previous_residential_lga",
      non_indigene?.previous_residential_lga
    );
    formData.append(
      "previous_residential_state",
      non_indigene?.previous_residential_state
    );
    formData.append("lga_of_origin", non_indigene?.lga_of_origin);
    formData.append("state_of_origin", non_indigene?.state_of_origin);
    formData.append("referee_name", non_indigene?.referee_name);
    formData.append("referee_phone", non_indigene?.referee_phone);
    formData.append("referee_hometown", non_indigene?.referee_hometown);
    formData.append("referee_lga", non_indigene?.referee_name);
    formData.append("referee_state", non_indigene?.referee_state);
    formData.append(
      "referee_residential_address",
      non_indigene?.referee_residential_address
    );
    formData.append("movein_date", non_indigene?.movein_date);
    formData.append("landlord_id", non_indigene?.landlord_id);
    formData.append("banner", file);
    formData.append("description", description);
    HttpService.postFormHeader("create_non_indigene", formData)
      .then((resp) => {
        console.log(resp);
        const severity =
          resp.status === 1
            ? "success"
            : resp.status === 0
            ? "error"
            : resp.status === 44
            ? "error"
            : "info";
        setModal({
          ...modal,
          onopen: true,
          message: resp.message,
          severity: severity,
        });
        if (resp.status === 1) {
        }
      })
      .catch((err) => {
        console.log(err);
        setModal({
          ...modal,
          onopen: true,
          severity: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const [tms, setTms] = React.useState<Dayjs | null>(dayjs());

  const setTime = React.useCallback(
    (e: any, name: string) => {
      setNonIndigene({ ...non_indigene, [name]: e?.toISOString() });
      console.log("::", non_indigene);
    },
    [non_indigene]
  );

  const {
    non_indigene_categories,
    loading_non_indigene_categories,
    loaded_non_indigene_categories,
  } = useFetchNonIndigeneCategories();

  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/non-indigenes">Non Indigenes</Link>
            <span>New Non Indigenes</span>
          </Breadcrumbs>
          <h1>New Non Indigene</h1>
        </div>
        <section className="page-detail-container">
          <div className="py30 px20">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer`}>
                  <label className="textfeld-control">
                    {"Non Indigene Category"}
                    <sup
                      className="boldest red"
                      title="This field is required!"
                    >
                      *
                    </sup>
                  </label>
                  <select
                    className={" input-form-control "}
                    name={"category_id"}
                    required
                    placeholder="Non Indigene Category"
                    onChange={handleInputChange}
                  >
                    <option value="">Select Non Indigene Category...</option>
                    {non_indigene_categories.map((item) => (
                      <option value={item.id} key={item.title}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer`}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                      <DateTimePicker
                        label="Moved In Date"
                        value={tms}
                        onChange={(e) => setTime(e, "movein_date")}
                        renderInput={(params: any) => (
                          <TextField className="flex spacer" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <label>Non Indigene Surname</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="surname"
                    onChange={handleInputChange}
                    placeholder={"Non Indigene Surname "}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Non Indigene Firstname</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="firstname"
                    onChange={handleInputChange}
                    placeholder={"Non Indigene Firstname"}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Non Indigene Othernames</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="othernames"
                    onChange={handleInputChange}
                    placeholder={"Non Indigene Othernames"}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                      <DesktopDatePicker
                        label="Pick a date for inspection"
                        inputFormat="MM/dd/yyyy"
                        value={non_indigene.movein_date}
                        onChange={(e) =>
                          setNonIndigene({ ...non_indigene, movein_date: e })
                        }
                        renderInput={(params: any) => <TextField {...params} />}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <label>Phone</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="phone"
                    onChange={handleInputChange}
                    placeholder={"Phone "}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Email Address</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="email"
                    onChange={handleInputChange}
                    placeholder={"Email Address"}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <label>Non Indigene Hometown</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="hometown"
                    onChange={handleInputChange}
                    placeholder={"Non Indigene Hometown"}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>LGA of Origin</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="lga_of_origin"
                    onChange={handleInputChange}
                    placeholder={"LGA of Origin"}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <label>State of Origin</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="state_of_origin"
                    onChange={handleInputChange}
                    placeholder={"State of Origin"}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Nationality</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="nationality"
                    onChange={handleInputChange}
                    placeholder={"Nationality"}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <label>Previous residential address</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="previous_residential_address"
                    onChange={handleInputChange}
                    placeholder={"Previous residential address"}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Previous Town of residence</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="previous_residential_town"
                    onChange={handleInputChange}
                    placeholder={"Previous Town of residence"}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <label>Previous LGA of Residence</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="previous_residential_lga"
                    onChange={handleInputChange}
                    placeholder={"Previous LGA of Residence"}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Previous State of residence</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="previous_residential_state"
                    onChange={handleInputChange}
                    placeholder={"Previous State of residence"}
                  />
                </div>
              </Grid>
            </Grid>

            <div className="banner-section">
              {preview_image && (
                <div className="image_preview">
                  <img className="" src={preview_image} alt="preview Image" />
                </div>
              )}
              <div
                className={
                  loading
                    ? " input mb0 iconed mt20 pt10"
                    : " input mb0 mt20 pt10"
                }
              >
                <label>Attach Picture</label>
                <input
                  type="file"
                  className="form-control"
                  name="file"
                  onChange={handleFileChange}
                  placeholder={"picture"}
                />
              </div>
            </div>

            <div className="mb10">
              <DefaultEditor
                className="form-control"
                placeholder="Non Indigene's full bio & description "
                value={description}
                onChange={onHtmlChange}
              />
            </div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Working..." : " Add non_indigene"}
            </Button>
          </div>
        </section>
      </Card>
      <CustomModal data={modal} />
    </div>
  );
};

export default NonIndigeneNew;
