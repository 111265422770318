import React from "react";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import Header from "../../Header/Header";
import PageIntro from "../PageIntro/PageIntro";
import "./Four0Four.css";
import Four0FourPane from "./Four0FourPane";
const Four0Four = () => {
  return (
    <React.Fragment>
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
      </section>
      <section className="container">
        <Four0FourPane />
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Four0Four;
