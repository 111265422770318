import React from "react";
import { Routes, Route } from "react-router-dom";

import EditPenalty from "./EditPenalty";
import PenaltiesList from "./PenaltiesList";
import PenaltyDetail from "./PenaltyDetail";
import CreatePenalty from "./CreatePenalty";

const Penalties = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<PenaltiesList />} />
        <Route path="/" element={<PenaltiesList />} />
        <Route path="/p/:mId" element={<PenaltyDetail />} />
        <Route path="/e/:mId" element={<EditPenalty />} />
        <Route path="/a/new" element={<CreatePenalty />} />
      </Routes>
    </React.Fragment>
  );
};

export default Penalties;
