import React from "react";
import { Routes, Route } from "react-router-dom";
import MeetingsList from "./MeetingsList";
import MeetingsDetail from "./MeetingsDetail";

const Meetings = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<MeetingsList />} />
        <Route path="/" element={<MeetingsList />} />
        <Route path="/p/:mId" element={<MeetingsDetail />} />
      </Routes>
    </React.Fragment>
  );
};

export default Meetings;
