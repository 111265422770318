import React from "react";
import { Link, useNavigate } from "react-router-dom";
import * as processHtml from "../../../services/processHtml";
import SeoModule from "../../../services/SeoModule";
import HttpService from "../../../services/HttpService";
import FaqPane from "./FaqPane";
import Header from "../../Header/Header";
import PageIntro from "../PageIntro/PageIntro";
import Footer from "../../Footer/Footer";

const FAQ = () => {
  let navigate = useNavigate();

  return (
    <React.Fragment>
      <SeoModule
        title="Frequently Asked Questions - CyprexCBT"
        description="Fintech: banking, crypto, investments"
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
        <PageIntro
          title="Frequently Asked Questions"
          summary="FAQ Page"
          loading_page={false}
        />
      </section>
      <div className="dashboard-pane">
        <div className="container">
          <div className="py20">
            <FaqPane />
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default FAQ;
