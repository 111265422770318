import React from "react";
import { Link } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import { Grid } from "@mui/material";
const HomeFeatures = () => {
  const features = [
    { path: "/free", text: "Peace", icon: "free.svg" },
    {
      path: "/free",
      text: "Unity",
      icon: "free.svg",
    },
    {
      path: "/free",
      text: "Progress",
      icon: "free.svg",
    },
  ];

  return (
    <React.Fragment>
      <section className="home-features">
        <div className="container">
          <div className="section-title">
            <h2>3 Pillars of Dianaokwu Village</h2>
          </div>

          <Grid container spacing={2}>
            {features.map((item) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={item.path}
                sx={{ p: "10px" }}
              >
                <div className="feature-container">
                  <div className="feature-icon">
                    <img
                      src={`/images/features/${item.icon}`}
                      alt={item.text}
                    />
                  </div>
                  <div className="feature-text"> {item.text}</div>
                  <div className="feature-link">
                    <Link to={`/features/${item.path}`}>See More</Link>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </section>
    </React.Fragment>
  );
};

export default React.memo(HomeFeatures);
