import React from "react";
import { Routes, Route, Link, NavLink } from "react-router-dom";
import useFetchPage from "../../../hooks/useFetchPage";
import useFetchKindreds from "../../../hooks/useFetchKindreds";
import {
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  Divider,
  Grid,
  Icon,
  LinearProgress,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import * as processHtml from "../../../services/processHtml";
import Header from "../../Header/Header";
import PageIntro from "../PageIntro/PageIntro";
import SeoModule from "../../../services/SeoModule";
import Footer from "../../Footer/Footer";
import { Settings } from "@mui/icons-material";

const KindredHome = () => {
  const { page, loading_page, loaded_page } = useFetchPage({
    slug: "kindreds",
  });
  const { kindreds, loading_kindreds, loaded_kindreds } = useFetchKindreds();

  const { decodeHtml, truncateWord } = processHtml;
  return (
    <React.Fragment>
      <SeoModule
        title={`Dianaokwu Ukaoye - Events - Kindreds`}
        description={"Kindreds"}
        name="Dianaokwu Ukaoye"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
        <PageIntro
          title={"Kindreds"}
          summary={"Kindreds"}
          loading_page={false}
        />
      </section>

      <section className="container py20">
        <Card sx={{ borderRadius: "0" }}>
          <div className="pxy20">
            {!loading_page && (
              <>
                <div
                  className="mt10"
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(page?.description),
                  }}
                ></div>
              </>
            )}

            {loading_page && <LinearProgress />}
          </div>
          <Divider />
          <div className="pxy20">
            <Grid container spacing={1.5}>
              {kindreds.map((item: any, index: number) => (
                <Grid item xs={12} sm={6} md={4} key={item.id}>
                  <div className="bordered br-5">
                    <ListItem
                      disablePadding
                      component={Link}
                      to={`/kindreds/${item.slug}`}
                    >
                      <ListItemButton>
                        <ListItemAvatar
                          sx={{
                            minWidth: "10px !important",
                            marginRight: "20px",
                            marginLeft: "10px",
                          }}
                        >
                          <h2>{index + 1}</h2>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            <h3 style={{ lineHeight: "1.9 !important" }}>
                              {item.name}
                            </h3>
                          }
                        ></ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </Card>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default KindredHome;
