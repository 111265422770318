import React from "react";
import { Routes, Route } from "react-router-dom";
import CreateMember from "./CreateMember";
import MembersList from "./MembersList";
import MemberDetail from "./MemberDetail";
import EditMember from "./EditMember";

const Members = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<MembersList />} />
        <Route path="/" element={<MembersList />} />
        <Route path="/p/:mId" element={<MemberDetail />} />
        <Route path="/e/:mId" element={<EditMember />} />
        <Route path="/a/new" element={<CreateMember />} />
      </Routes>
    </React.Fragment>
  );
};

export default Members;
