import { Card, LinearProgress } from "@mui/material";
import React from "react";
import { Routes, Route, useParams } from "react-router-dom";
import PageIntro from "../PageIntro/PageIntro";
import Header from "../../Header/Header";
import SeoModule from "../../../services/SeoModule";
import HttpService from "../../../services/HttpService";
import * as processHtml from "../../../services/processHtml";
import SideLinks from "../../templates/SideLinks";

const KindredDetails = () => {
  const params = useParams();

  console.log("paa::", params);
  const isParam = params.url ? true : false;
  const { decodeHtml, truncateWord } = processHtml;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [kindred, setKindred] = React.useState<any>({});
  let [slug, setSlug] = React.useState<any>("");

  React.useEffect(() => {
    getKindred(params?.url);
    setSlug(params?.url);
  }, [params?.url]);

  const getKindred = (url: string | null | undefined) => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("kindred_details", { id: url })
      .then(
        (res) => {
          console.log(res);
          setKindred(res.data);
        },
        (error) => {
          console.log(error);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  const links: any[] = [
    { path: `/kindreds/${kindred.slug}`, title: "Introduction" },
    { path: `/kindreds/${kindred.slug}/executives`, title: "Executives" },
    {
      path: `/kindreds/${kindred.slug}/past-executives`,
      title: "Past Executives",
    },
    { path: `/kindreds/${kindred.slug}/history`, title: "History" },
    { path: `/kindreds/${kindred.slug}/heritage`, title: "Heritage" },
  ];
  return (
    <React.Fragment>
      <SeoModule
        title={`Dianaokwu Ukaoye - Events - Kindreds`}
        description={"Kindreds"}
        name="Dianaokwu Ukaoye"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
        <PageIntro
          title={slug}
          summary={"Kindreds"}
          sub_text={"Kindreds"}
          sub_link="/kindreds"
          loading_page={false}
        />
      </section>

      <section className="container py20">
        <Card sx={{ borderRadius: "0" }}>
          <div className="about-container">
            <SideLinks links={links} loading={loading} loaded={loaded} />
            <div className="main-about-content">
              <Routes>
                <Route
                  path=""
                  element={<Intro kindred={kindred} loading={loading} />}
                />
                <Route
                  path="/"
                  element={<Intro kindred={kindred} loading={loading} />}
                />
                <Route
                  path="/:space"
                  element={<Space kindred={kindred} loading={loading} />}
                />
              </Routes>
            </div>
          </div>
        </Card>
      </section>
    </React.Fragment>
  );
};

const Intro = (props: any) => {
  const { kindred, loading } = props;
  const { decodeHtml, truncateWord } = processHtml;
  return (
    <React.Fragment>
      {loading && <LinearProgress />}
      {!loading && (
        <div
          className="mt10"
          dangerouslySetInnerHTML={{
            __html: decodeHtml(kindred?.description),
          }}
        ></div>
      )}
    </React.Fragment>
  );
};
const Space = (props: any) => {
  const pars = useParams();

  console.log("pars::", pars);
  const { kindred, loading } = props;
  const { decodeHtml, truncateWord } = processHtml;
  return (
    <React.Fragment>
      <section>Space:{pars.space}</section>
    </React.Fragment>
  );
};

export default KindredDetails;
