import React from "react";
import { Routes, Route } from "react-router-dom";
import LeviesList from "./LeviesList";
import LevyDetail from "./LevyDetail";
import EditLevy from "./EditLevy";
import CreateLevy from "./CreateLevy";

const Levies = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<LeviesList />} />
        <Route path="/" element={<LeviesList />} />
        <Route path="/p/:mId" element={<LevyDetail />} />
        <Route path="/e/:mId" element={<EditLevy />} />
        <Route path="/a/new" element={<CreateLevy />} />
      </Routes>
    </React.Fragment>
  );
};

export default Levies;
