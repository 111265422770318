import { Button } from "@mui/material";
import React from "react";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import * as processHtml from "../../../services/processHtml";

const HomeLeaveMessage = () => {
  const { decodeHtml } = processHtml;
  const [form, setForm] = React.useState<any>({
    name: "",
    email: "",
    message: "",
  });
  const handleInput = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    setForm({ ...form, [name]: value });
  };
  const [user, setUser] = React.useState<any>(null);
  const [input_togged, setInputTog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const closeToast = () => {
    setToast({ onopen: false });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: closeToast,
  });

  const handleSubmit = () => {
    console.log(form);
    if (form.name === "" || form.email === "" || form.message === "") {
      setToast({
        message: "Please supply all fields",
        onclose: closeToast,
        onopen: true,
        severity: "error",
      });
      return;
    }
    setLoading(true);
    setLoaded(false);
    setToast({
      onopen: true,
      onclose: closeToast,
      message: "<h3>Sending message...</h3>",
    });
    HttpService.postForm("contact_message", form)
      .then(
        (resp) => {
          setToast({
            onopen: true,
            onclose: closeToast,
            message: resp.message,
          });
          if (resp.status == "1") {
            setForm({ message: "", name: "", email: "" });
            setTimeout(() => {
              setToast({
                onopen: false,
                onclose: closeToast,
              });
            }, 5000);
          }
        },
        (error) => {
          setToast({
            onopen: true,
            onclose: closeToast,
            severity: "error",
            message: error.message,
          });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  return (
    <React.Fragment>
      <section className="message-area-container">
      <div className="black-area">
        <div className="container">
          <div className="flex flex-row-resp">
            <div className="contact-info-area spacer">
              <h2>Contact us for more info about Dianaokwu.</h2>

              <p>
                Donec quam felis, ultricies nec, pellentesque eu, pretium quis,
                sem. Nulla consequat massa quis enim.
              </p>

              <div className="parp">
                <h3>Contact Us</h3>
                <ul className="cont-list">
                  <li>
                    <a href="https://twitter.com/UrumTown">
                      <i className="fa-brands fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://twitter.com/UrumTown">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/UrumTown/">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="t.me/UrumTown">
                      <i className="fa-brands fa-telegram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            {/**contaact-info ends */}
            <div className="contact-area spacer">
              <div className="push-form">
                <div className="input iconed hma">
                  <input
                    type="text"
                    name="name"
                    value={form.name}
                    className="home-form-control"
                    placeholder="Enter your full name"
                    onChange={handleInput}
                  />
                  <span className="input-icon homs">
                    <i className="fas fa-user"></i>
                  </span>
                </div>
                <div className="input iconed hma">
                  <input
                    type="text"
                    name="phone"
                    value={form.phone}
                    className="home-form-control"
                    placeholder="Enter your phone number"
                    onChange={handleInput}
                  />
                  <span className="input-icon homs">
                    <i className="fas fa-phone"></i>
                  </span>
                </div>

                <div className="input iconed hma">
                  <input
                    type="text"
                    name="email"
                    value={form.email}
                    className="home-form-control"
                    placeholder="Enter your email address"
                    onChange={handleInput}
                  />
                  <span className="input-icon homs">
                    <i className="fas fa-envelope"></i>
                  </span>
                </div>
                {/* <div className="input iconed hma">
                  <select
                    placeholder="Select origin..."
                    value={form.origin}
                    className="home-form-control"
                    defaultValue={"indigene"}
                    onChange={handleInput}
                  >
                    <option value={"indigene"}>I am from Urum</option>
                    <option value={"non-indigene"}>I am NOT from Urum</option>
                  </select>
                  <span className="input-icon homs">
                    <i className="fas fa-map-marker"></i>
                  </span>
                </div> */}
                <div className="input hma">
                  <textarea
                    name="message"
                    value={form.message}
                    rows={5}
                    className="home-form-control"
                    placeholder="Write message..."
                    onChange={handleInput}
                  ></textarea>
                </div>

                <button
                  onClick={handleSubmit}
                  disabled={loading}
                  type="submit"
                  className="hm-contact-btn"
                >
                  {loading ? "Working..." : "Submit"}
                </button>
              </div>
            </div>
            {/**contact-area ends */}
          </div>
          {/**container ends */}
        </div>
      </div>
      </section>
      <CustomModal data={toast} />
    </React.Fragment>
  );
};

export default HomeLeaveMessage;
