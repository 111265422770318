import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SeoModule from "../../../services/SeoModule";
import useFetchPage from "../../../hooks/useFetchPage";
import * as processHtml from "../../../services/processHtml";
import LinearProgress from "@mui/material/LinearProgress";
import PageIntro from "../PageIntro/PageIntro";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

const Privacy = () => {
  const { decodeHtml, truncateWord } = processHtml;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { page, loaded_page, loading_page } = useFetchPage({ slug: "privacy" });
  return (
    <React.Fragment>
      <SeoModule
        title="CyprexCBT - Privacy Policy"
        description="Fintech: banking, crypto, investments"
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
        <PageIntro
          title={page.title}
          summary={page.title}
          loading_page={loading_page}
        />
      </section>
      <section className="dashboard-pane">
        <div className="container">
          <Card sx={{ borderRadius: "0" }}>
            <div className="px20">
              {loading_page && <LinearProgress />}
              {!loading_page && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: decodeHtml(page.description),
                  }}
                ></div>
              )}
            </div>
          </Card>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Privacy;
