import { Breadcrumbs, Button, Card } from "@mui/material";
import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import AuthService from "../../../services/AuthService";
import ClearanceList from "./ClearanceList";
const usr = AuthService.getCurrentUser();
const MembershipHome = () => {
  console.log("memberhip home loads....");

  return (
    <React.Fragment>
      <section className="account-page-container">
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/account">Dashboard</Link>
              <span>Membership</span>
            </Breadcrumbs>
            <h1>Membership</h1>
          </div>
          <section className="page-detail-container">
            <div className="flex flex-row border-bottom align-items-center pxy10">
              <span className="spacer"></span>
              <span>
                <Button variant="outlined" size="small">
                  Pay Clearance
                </Button>
              </span>
            </div>
            <ClearanceList />
          </section>
        </Card>
      </section>
    </React.Fragment>
  );
};

export default MembershipHome;
