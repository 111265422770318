import React, { useState } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { DefaultEditor } from "react-simple-wysiwyg";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";

const MessagesReply = (props: any) => {
  document.title = "Reva Bank - Dashboard - Reply Ticket";
  let params = useParams();
  const [isParam, setParam] = React.useState(false);

  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [reply_data, setData] = useState({
    id: "",
    pid: "",
    message: "",
    subject: "",
  });

  React.useEffect(() => {
    const isParam = params.ticketId ? true : false;
    setParam(isParam);
    if (isParam) {
      fetchDetails(params.ticketId);
    }
  }, []);

  const fetchDetails = (id: any) => {
    const endPoint = "messages/detail/" + id;
    HttpService.get(endPoint)
      .then(
        (response) => {
          console.log(response);
          setLoading(false);
          setData({
            ...reply_data,
            subject: response.data.subject,
            id: response.data.id,
            pid: response.data.id,
          });
          setLoaded(true);
        },
        (error) => {
          setError(error.message);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  }; //fetChdetails;

  const [response, setResponse] = useState({});
  const [error, setError] = useState("");

  const onToastClose = () => {
    setSnack({ onopen: false });
  };
  const [snack, setSnack] = useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });

  const handleInputChange = (event: any) => {
    const targ = event.target;
    const name = targ.name;
    const value = targ.value;
    setData({ ...reply_data, [name]: value });
    console.log(reply_data);
  };
  const onHtmlChange = (e: any) => {
    setMessage(e.target.value);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(reply_data);
    const obj: any = { ...reply_data, message: message };
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("messages/reply", { data: obj })
      .then(
        (resp) => {
          console.log(resp);
          setSnack({ ...snack, onopen: true, message: resp.message });
          if (resp.status === 1) {
            setTimeout(() => {
              setSnack({ ...snack, onopen: false });
              return navigate(`/account/messages/p/${params.ticketId}`);
            }, 3000);
          }
        },
        (error) => {
          setSnack({ ...snack, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  const navigate = useNavigate();
  const goDetail = () => {
    return navigate(`/account/messages/p/${params.ticketId}`);
  };
  const goHome = () => {
    return navigate(`/account/tickets`);
  };

  return (
    <>
      {" "}
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          padding: "10px",
          width: "100%",
        }}
      >
        <Link to={`/account/messages`}>Messages</Link>

        <Link to={`/account/messages/p/${reply_data?.id}`}>
          {reply_data?.subject}
        </Link>
        <span>Reply</span>
      </Breadcrumbs>
      <div className="px10 pb10">
        <Card sx={{ p: "0", m: "0" }}>
          <form onSubmit={handleSubmit}>
            <div style={{ padding: "20px" }}>
              <div className="relative">
                <TextField
                  name="subject"
                  value={"Re: " + reply_data.subject}
                  onChange={handleInputChange}
                  fullWidth
                  sx={{ marginBottom: "4px" }}
                  placeholder={"Subject"}
                  multiline={false}
                />
              </div>

              <div className="relative py10">
                <DefaultEditor value={message} onChange={onHtmlChange} />
              </div>

              <Button type="submit" variant="contained">
                Send Reply
              </Button>
            </div>
          </form>
        </Card>
      </div>
      <CustomModal data={snack} />
    </>
  );
};

export default MessagesReply;
