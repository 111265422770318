import React from "react";
import Header from "../../Header/Header";
import PageIntro from "../PageIntro/PageIntro";
import { Link } from "react-router-dom";
import Footer from "../../Footer/Footer";
import SeoModule from "../../../services/SeoModule";
import useFetchLiveCategories from "../../../hooks/useFetchLiveCategories";
const LiveLayout = (props: any) => {
  const { children, page_data } = props;
  const { live_categories, loading_live_categories, loaded_live_categories } =
    useFetchLiveCategories();

  let nave = [...live_categories];
  const [default_subject, setDefSub] = React.useState("Events");
  const [cur_tab, setTab] = React.useState<any>("events");
  React.useEffect(() => {
    setDefSub(page_data?.title);
    setTab(page_data?.page_slug);
  }, [page_data]);
  console.log("page data::", page_data);

  return (
    <React.Fragment>
      <SeoModule
        title={`Dianaokwu Ukaoye - Live Streams - ${page_data?.page_title}`}
        description={page_data?.page_summary}
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
        <PageIntro
          title={page_data.page_title}
          summary={page_data.page_summary}
          loading_page={false}
        />
      </section>

      <section className="container py20">
        {live_categories && (
          <div className="mb20">
            <ul className="category-tab">
              {nave.map((item, index) => (
                <li key={item.title}>
                  <Link
                    to={`/live/${item.slug}`}
                    onClick={() => setTab(item.slug)}
                    className={
                      item.slug === page_data.page_slug ? "active" : ""
                    }
                  >
                    {item.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        )}

        <div className="p0">{children}</div>
      </section>
    </React.Fragment>
  );
};

export default LiveLayout;
