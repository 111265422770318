import React from "react";
import HttpService from "../services/HttpService";

const useFetchLocationTypes = () => {
  const [location_types, setTypes] = React.useState<any[]>([]);
  const [loading_location_types, setLoading] = React.useState(false);
  const [loaded_location_types, setLoaded] = React.useState(false);

  React.useEffect(() => {
    fetch_kindred();
  }, []);

  const fetch_kindred = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("list_location_types", {})
      .then(
        (res) => {
          if (res.status === 1) {
            setTypes(res.data);
          }
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return { location_types, loading_location_types, loaded_location_types };
};

export default useFetchLocationTypes;
