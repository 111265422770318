import React from "react";
import Button from "@mui/material/Button";
import CustomModal from "../../templates/CustomModal";
import HttpService from "../../../services/HttpService";
import AuthService from "../../../services/AuthService";
const EditPassword = (props: any) => {
  console.log(props);
  let { user, onopen, onClose, handler } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>(null);
  const modalClose = () => setModal({ ...modal_data, onopen: false });
  const [modal_data, setModal] = React.useState<any>({
    onopen: false,
    onclose: modalClose,
  });

  const dts = user?.dob ? new Date(user?.dob) : new Date();
  const [fetched, setFetched] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (user) {
      setData({
        ...user,
        dob: dts,
      });
      setFetched(true);
    }
  }, []);
  const handleInput = React.useCallback(
    (e: any) => {
      const name = e.target.name;
      const value = e.target.value;
      setData({ ...data, [name]: value });
      console.log(data);
    },
    [data]
  );

  const submit_handler = () => {
    setLoading(true);
    setLoaded(false);
    setData({ ...data, mode: "change" });
    console.log(data);
    HttpService.postHeader("change_password", data)
      .then(
        (result) => {
          console.log(result);
          const severity =
            result.status === 1
              ? "success"
              : result.status === 0
              ? "error"
              : "info";
          setModal({
            ...modal_data,
            onopen: true,
            message: result.message,
            severity: severity,
            onclose: modalClose,
          });
          console.log("::|", result);
          if (result.status === 1) {
            return AuthService.logout();
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };
  return (
    <React.Fragment>
      <div className="pxy20">
        <h3 className="mb10">
          <i className="fas fa-lock"></i> Update Password
        </h3>
        <div className="flex flex-row-resp align-items-center">
          <div className="input iconed spacer">
            <label>Old Password</label>
            <input
              type="password"
              name="old_password"
              className="input-form-control"
              placeholder="Old Password"
              onChange={handleInput}
            />
            <span className="input-icon">
              <i className="fas fa-lock"></i>
            </span>
          </div>
        </div>

        <div className="flex flex-row-resp align-items-center">
          <div className="input iconed spacer pr10">
            <label>New Password</label>
            <input
              type="password"
              name="new_password"
              className="input-form-control"
              placeholder="New Password"
              onChange={handleInput}
            />
            <span className="input-icon">
              <i className="fas fa-lock"></i>
            </span>
          </div>

          <div className="input iconed spacer">
            <label>Confirm New Password</label>
            <input
              type="password"
              name="re_password"
              className="input-form-control"
              placeholder="Confirm New Password"
              onChange={handleInput}
            />
            <span className="input-icon">
              <i className="fas fa-lock"></i>
            </span>
          </div>
        </div>

        <div className="flex flex-row align-items-center">
          <span className="">
            <Button
              onClick={submit_handler}
              size="large"
              disabled={loading}
              variant="contained"
              fullWidth
              type="submit"
            >
              {loading ? "Working..." : "Update Password"}
            </Button>
          </span>
          <span className="spacer"></span>
          <span className="pl5">
            <Button
              color="warning"
              onClick={() => props.handler(false)}
              size="large"
            >
              Exit
            </Button>
          </span>
        </div>
      </div>
      <CustomModal data={modal_data} />
    </React.Fragment>
  );
};

export default EditPassword;
