import React from "react";
import LoginForm from "./LoginForm";
import SeoModule from "../../../services/SeoModule";
import LoginModule from "./LoginModule";
import { Link } from "react-router-dom";

const Login = () => {
  return (
    <React.Fragment>
      <SeoModule
        title="Login - CyprexCBT"
        description="Login"
        name="CyprexCBT"
        type="Crypto Investment"
      />
      <section className="page-main">
        <div className="home-overlay"></div>
        <div className="login-space">
          <div className="auth-logo-space">
            <Link className="logo" to="/">
              <img src={`/images/logo2.png`} alt="CyprexCBT Logo" />
            </Link>
          </div>
        </div>
        <div className="login-cover">
          <div className="login-pane">
            <LoginModule />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Login;
