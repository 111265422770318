import React from "react";
import { Routes, Route } from "react-router-dom";
import KindredHome from "./KindredHome";
import KindredDetails from "./KindredDetails";
import Footer from "../../Footer/Footer";
const Kindreds = () => {
  return (
    <React.Fragment>
      <section className="bg-diff">
        <Routes>
          <Route path="" element={<KindredHome />} />
          <Route path="/" element={<KindredHome />} />
          <Route path="/:url/*" element={<KindredDetails />} />
        </Routes>
      </section>
      <Footer/>
    </React.Fragment>
  );
};

export default Kindreds;
