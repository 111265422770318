import {
  AccountCircleOutlined,
  BackupOutlined,
  CameraAltOutlined,
  ChevronRightOutlined,
  LogoutOutlined,
  MultilineChartOutlined,
  Password,
  SettingsAccessibility,
  TableChartOutlined,
  Timer,
  VolumeMuteOutlined,
  VolumeOffOutlined,
  VolumeUpOutlined,
} from "@mui/icons-material";
import {
  Card,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Slider,
  Avatar,
  Switch,
  Icon,
  IconButton,
} from "@mui/material";
import React from "react";
import CustomComponent from "../../templates/CustomComponent";
import AuthService from "../../../services/AuthService";

const Settings = ({ user }: { user: any }) => {
  const [vals, setVals] = React.useState<any>({
    backup: true,
    sounds: false,
    show_timer: false,
    leaderboard: true,
  });
  React.useEffect(() => {
    setVals(user.profile_settings);
  }, [user.profile_settings]);

  const handleChange = React.useCallback(
    (e: any, key: string) => {
      console.log(e.target.checked);
      setVals({ ...vals, [key]: e.target.checked });
      console.log(vals);
    },
    [vals]
  );
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
  });

  const launchComponent = (type: number) => {
    setModal({ ...modal, onopen: true, user: user, type: type });
  };
  function valuetext(value: number) {
    return `${value}min`;
  }

  return (
    <React.Fragment>
      <section className="pxy20">
        <div className="avatar-pane flex flex-column justify-content-center pxy10">
          <Avatar
            alt={user.name}
            src={process.env.REACT_APP_SERVER_DOMAIN + user.avatar}
            sx={{ width: 150, height: 150 }}
          >
            <IconButton onClick={() => launchComponent(2)}>
              <CameraAltOutlined sx={{ fontSize: "30px" }} />
            </IconButton>
          </Avatar>
        </div>

        <h3>Account Settings</h3>
        <Card sx={{ m: "5px 0 20px 0" }}>
          <List sx={{ p: "0", m: "0" }}>
            <ListItem
              divider
              secondaryAction={
                <Switch onChange={(e) => handleChange(e, "backup")} />
              }
            >
              <ListItemIcon>
                {vals.backup ? (
                  <BackupOutlined color="primary" />
                ) : (
                  <BackupOutlined />
                )}
              </ListItemIcon>
              <ListItemText primary={<>Back Up Scores to Web</>}></ListItemText>
            </ListItem>
            <ListItem
              divider
              secondaryAction={
                <Switch onChange={(e) => handleChange(e, "leaderboard")} />
              }
            >
              <ListItemIcon>
                {vals.leaderboard ? (
                  <MultilineChartOutlined color="primary" />
                ) : (
                  <MultilineChartOutlined />
                )}
              </ListItemIcon>
              <ListItemText
                primary={<>List me on Leaderboard</>}
              ></ListItemText>
            </ListItem>
            <ListItem
              divider
              secondaryAction={
                <Switch onChange={(e) => handleChange(e, "sounds")} />
              }
            >
              <ListItemIcon>
                {vals.sounds ? (
                  <VolumeUpOutlined color="primary" />
                ) : (
                  <VolumeOffOutlined />
                )}
              </ListItemIcon>
              <ListItemText primary={<>Turn on sounds</>}></ListItemText>
            </ListItem>
            <ListItem divider>
              <ListItemIcon>
                <Timer />
              </ListItemIcon>
              <ListItemText
                primary={<>Timer reminder shows at :</>}
                secondary={
                  <div className="pxy20">
                    <Slider
                      onChange={(e) => handleChange(e, "show_timer")}
                      step={1}
                      min={1}
                      max={10}
                      getAriaValueText={valuetext}
                      orientation="horizontal"
                      valueLabelDisplay="auto"
                    />
                  </div>
                }
              ></ListItemText>
            </ListItem>
          </List>
        </Card>
        <h3>Profile Settings</h3>
        <Card sx={{ m: "5px 0 20px 0" }}>
          <List sx={{ p: "0", m: "0" }}>
            <ListItem
              divider
              disablePadding
              secondaryAction={<ChevronRightOutlined />}
            >
              <ListItemButton onClick={() => launchComponent(0)}>
                <ListItemIcon>
                  <AccountCircleOutlined />
                </ListItemIcon>
                <ListItemText primary={<>Edit Details</>}></ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              divider
              disablePadding
              secondaryAction={<ChevronRightOutlined />}
            >
              <ListItemButton onClick={() => launchComponent(1)}>
                <ListItemIcon>
                  <Password />
                </ListItemIcon>
                <ListItemText primary={<>Change Password</>}></ListItemText>
              </ListItemButton>
            </ListItem>
            <ListItem
              divider
              disablePadding
              secondaryAction={<ChevronRightOutlined />}
            >
              <ListItemButton
                onClick={() => {
                  return AuthService.logout();
                }}
              >
                <ListItemIcon>
                  <LogoutOutlined color="error" />
                </ListItemIcon>
                <ListItemText primary={<>Log Out</>}></ListItemText>
              </ListItemButton>
            </ListItem>
          </List>
        </Card>
      </section>
      <CustomComponent data={modal} />
    </React.Fragment>
  );
};
export default Settings;
