import React from "react";
import {
  Slide,
  Divider,
  Button,
  DialogActions,
  DialogContent,
  Dialog,
} from "@mui/material";
import LoginModule from "../public/Login/LoginModule";

const Transition = React.forwardRef(function Transition(props: any, ref: any) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PromptLogin = (props: any) => {
  const { button_text, return_url } = props;
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };

  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [modal, setModal] = React.useState({
    onopen: false,
    onclose: closeModal,
    title: "User Login",
  });
  const launchModal = () => {
    setModal({ ...modal, onopen: true, onclose: closeModal });
  };
  return (
    <>
      <Button
        type="submit"
        size="small"
        variant="contained"
        disabled={loading}
        onClick={launchModal}
      >
        {loading ? "Working..." : button_text}
      </Button>

      <Dialog
        fullScreen={false}
        TransitionComponent={Transition}
        open={modal.onopen}
        onClose={modal.onclose}
        scroll={"paper"}
        aria-labelledby={"Me"}
      >
        <DialogContent>
          <section>
            <LoginModule intro_message="Please login to your account to comment" />
          </section>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={modal.onclose} color="warning">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default React.memo(PromptLogin);
