import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import AccountTicketDetails from "./MessagesDetails";
import AccountTicketList from "./MessagesList";
import AccountTicketNew from "./MessagesNew";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Add from "@mui/icons-material/Add";
import AccountTicketReply from "./MessagesReply";

const Messages = (props: any) => {
  const navigate = useNavigate();

  const launchNew = () => {
    return navigate(`/account/messages/a/new`);
  };
  return (
    <React.Fragment>
      <div
        className="page-top-intro flex px20 py10 align-items-center 
      justify-content-center"
      >
        <div className="px10">
          <h3 className="py0 my0 px0 mx0">
            <i className="fas fa-ticket"></i> Support Tickets
          </h3>
        </div>
        <div className="spacer"></div>
        <div>
          <Tooltip title="Create new Ticket">
            <Button variant="contained" size="small" onClick={launchNew}>
              <Add /> Create New
            </Button>
          </Tooltip>
        </div>
      </div>
      <Divider />
      <Routes>
        <Route path="" element={<AccountTicketList />} />
        <Route path="/" element={<AccountTicketList />} />
        <Route path="/p/:ticketId" element={<AccountTicketDetails />} />
        <Route path="/r/:ticketId" element={<AccountTicketReply />} />
        <Route path="/a/new" element={<AccountTicketNew />} />
      </Routes>
    </React.Fragment>
  );
};

export default Messages;
