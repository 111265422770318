import React from "react";
const Homepage = () => {
  return (
    <React.Fragment>
      <div>HOMEPAGE</div>
    </React.Fragment>
  );
};

export default Homepage;
