import React from "react";
import HomeMain from "./HomeMain";
import HomeLeaveMessage from "./HomeLeaveMessage";
import HomeChairmanIntro from "./HomeChairmanIntro";
import Ichie from "./Ichie";
import HomeFeatures from "./HomeFeatures";
import Footer from "../../Footer/Footer";
import HomeEvents from "./HomeEvents";
import "./Home.css";
import HomeFaq from "./HomeFaq";

const Home = () => {
  return (
    <React.Fragment>
      <HomeMain />
      <HomeFeatures />
      <HomeChairmanIntro />
      <Ichie />
      <HomeEvents />
      <HomeLeaveMessage />
      <HomeFaq />
      <Footer />
    </React.Fragment>
  );
};

export default Home;
