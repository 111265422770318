import React from "react";
import FaqPane from "../FAQ/FaqPane";
const HomeFaq = () => {
  React.useEffect(() => {}, []);

  return (
    <React.Fragment>
      <div className="home-faq" id="faq">
        <div className="container">
          <div className="section-title">
            <h2>Frequently Asked Questions</h2>
          </div>

          <FaqPane />
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeFaq;
