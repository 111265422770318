import React from "react";
import { Link } from "react-router-dom";
import "./Four0Four.css";
const Four0FourPane = (props: any) => {
  const { home } = props;
  return (
    <React.Fragment>
      <div className="four-o-four">
        <h1>!404</h1>
        <h2>The page you are looking for does not exist!</h2>
        <h3>You may have typed the wrong address or the page may have moved</h3>
        <div>
          <Link className="go-home" to={home ? home : "/"}>
            Go Home
          </Link>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Four0FourPane;
