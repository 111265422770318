import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SeoModule from "../../../services/SeoModule";
import useFetchPage from "../../../hooks/useFetchPage";
import * as processHtml from "../../../services/processHtml";
import { LinearProgress } from "@mui/material";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import PageIntro from "../PageIntro/PageIntro";

const Audios = () => {
  const { decodeHtml, truncateWord } = processHtml;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { page, loaded_page, loading_page } = useFetchPage({
    slug: "about",
  });
  return (
    <React.Fragment>
      <SeoModule
        title="Audios - Reva"
        description="Fintech: banking, crypto, investments"
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <section className="page-main">
        <Header />
        <PageIntro
          title="Audios"
          summary="Audios Page"
          loading_page={loading_page}
        />
      </section>
      <section className="container py20">
        <Card sx={{ borderRadius: "0" }}>
          <div className="pxy20">
            {loading_page && <LinearProgress />}
            {!loading_page && (
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeHtml(page.description),
                }}
              ></div>
            )}
          </div>
        </Card>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Audios;
