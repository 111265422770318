import React from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import {
  Breadcrumbs,
  Card,
  Grid,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import useFetchKindreds from "../../../hooks/useFetchKindreds";
import useFetchQuaters from "../../../hooks/useFetchQuaters";
import useFetchLocationTypes from "../../../hooks/useFetchLocationTypes";
import CustomModal from "../../templates/CustomModal";
import {
  Delete,
  Edit,
  Settings,
  ToggleOff,
  ToggleOn,
} from "@mui/icons-material";
import ConfirmModal from "../../templates/ConfirmModal";
import PlaceHolder from "../../templates/PlaceHolder";

const MemberDetail = () => {
  const params = useParams();
  const isParam = params.mId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [member, setMember] = React.useState<any>({ designation: "0" });

  React.useEffect(() => {
    getMember(params.mId);
  }, [params?.mId]);

  const getMember = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ id: id }, "member_details")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setMember(result.data);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const closeDelModal = () => {
    setDelModal({ ...del_modal, onopen: false });
  };
  const [del_modal, setDelModal] = React.useState<any>({
    onopen: false,
    onclose: closeDelModal,
    title: "Delete member",
  });

  let navigate = useNavigate();
  const togEdit = (i: any) => {
    return navigate(`/admin/members/e/${i.id}`);
  };

  const launchNew = (i: any) => {
    return navigate(`/admin/members/a/new`);
  };

  const delete_member = (item: any) => {
    setLoading(true);
    setLoaded(false);
    setDelModal({
      ...del_modal,
      loading: true,
      message: null,
      loading_message: "Working...",
    });
    HttpService.post({ id: item.id }, "remove_member")
      .then(
        (resp) => {
          if (resp.status === 1) {
            setModal({
              ...modal,
              onopen: true,
              severity: "success",
              message: resp.message,
            });
          }
          setTimeout(() => {
            setDelModal({
              ...del_modal,
              onopen: false,
              onclose: closeDelModal,
            });
            setModal({ ...modal, onopen: false, onclose: closeModal });
          }, 3000);
        },
        (error) => {
          setModal({
            ...modal,
            onopen: true,
            severity: "error",
            message: error.message,
          });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit member",
    loading_message: null,
    loading: false,
  });

  const activate_member = (item: any) => {
    setLoading(true);
    setLoaded(false);
    setDelModal({
      ...del_modal,
      loading: true,
      message: null,
      loading_message: "Working...",
    });
  };

  const launchDelete = (item: any) => {
    setDelModal({
      ...del_modal,
      id: item.id,
      loading_message: null,
      loading: false,
      message:
        "Are you sure you want to <strong><u>remove</u></strong> this member?",
      onopen: true,
      onclose: closeDelModal,
      onaccept: delete_member,
    });
  };
  const launchActivate = (item: any) => {
    const act = item.is_activated == "1" ? "deactivate" : "activate";
    setDelModal({
      ...del_modal,
      id: item.id,
      action: act,
      title: act + " member",
      message: `Are you sure you want to <strong><u>${act}</u></strong> this member?`,
      onopen: true,
      loading_message: null,
      loading: false,
      onclose: closeDelModal,
      onaccept: () => activate_member({ id: item.id, action: act }),
    });
  };
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/members">Members</Link>
          </Breadcrumbs>
          <h1> {member?.name}</h1>
        </div>
        <section className="page-detail-container">
          <div className="flex flex-row-resp">
            <div className="pxy10">
              <div className="picture-card">
                <img
                  src={process.env.REACT_APP_SERVER_ROOT + member.picture}
                  alt={member.name}
                />
              </div>
            </div>
            <div className="pxy10 spacer">
              {loading && <PlaceHolder type="profile" />}
              {loaded && (
                <Card>
                  <ListItem divider disablePadding>
                    <ListItemText
                      primary={
                        <div className="flex flex-row align-items-center px20">
                          <span className="spacer txt-lg">
                            <i className="fas fa-cog"></i>
                          </span>
                          <span>
                            <Tooltip title="Edit member">
                              <IconButton
                                size="medium"
                                onClick={() => togEdit(member)}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Remove member">
                              <IconButton
                                color="warning"
                                size="medium"
                                onClick={() => launchDelete(member)}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                            <Tooltip
                              title={
                                member.is_activated == "1"
                                  ? "Deactivate member"
                                  : "Activate member"
                              }
                            >
                              <IconButton
                                color={
                                  member.is_active == "1"
                                    ? "default"
                                    : "primary"
                                }
                                size="medium"
                                onClick={() => launchActivate(member)}
                              >
                                {member.is_active == "1" ? (
                                  <ToggleOff />
                                ) : (
                                  <ToggleOn />
                                )}
                              </IconButton>
                            </Tooltip>
                          </span>
                        </div>
                      }
                    ></ListItemText>
                  </ListItem>

                  <ListItem divider>
                    <ListItemText
                      secondary={<h3>{member.name || ""}</h3>}
                      primary={`Name`}
                    ></ListItemText>
                  </ListItem>

                  <ListItem divider>
                    <ListItemText
                      secondary={<h3>{member.phone || ""}</h3>}
                      primary={`Phone`}
                    ></ListItemText>
                  </ListItem>

                  <ListItem divider>
                    <ListItemText
                      secondary={<h3>{member.email || ""}</h3>}
                      primary={`Email`}
                    ></ListItemText>
                  </ListItem>

                  <ListItem divider>
                    <ListItemText
                      secondary={<h3>{member.kindred_name || ""}</h3>}
                      primary={`Kindred`}
                    ></ListItemText>
                  </ListItem>

                  <ListItem divider>
                    <ListItemText
                      secondary={<h3>{member.quarter_name || "--"}</h3>}
                      primary={`Quarter`}
                    ></ListItemText>
                  </ListItem>

                  <ListItem divider>
                    <ListItemText
                      secondary={<h3>{member.location_type_title || "--"}</h3>}
                      primary={`Location Type`}
                    ></ListItemText>
                  </ListItem>

                  <ListItem divider>
                    <ListItemText
                      secondary={<h3>{member.state_of_residence || "--"}</h3>}
                      primary={`State of Residence`}
                    ></ListItemText>
                  </ListItem>
                </Card>
              )}
            </div>
          </div>
        </section>
      </Card>
      <CustomModal data={modal} />
      <ConfirmModal data={del_modal} loading={loading} />
    </div>
  );
};

export default MemberDetail;
