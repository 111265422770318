import HttpService from "./HttpService";
class AuthService {
  logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("access_token");
    localStorage.removeItem("expire_at");
    return (window.location.href = "/login");
  }
  getCurrentUser() {
    const userJson = localStorage.getItem("user");
    const user = userJson !== null ? JSON.parse(userJson) : {};
    return user;
  }

  updateUser(user: any, reload = false) {
    localStorage.setItem("user", JSON.stringify(user));
    if (reload) {
      return (window.location.href = "/account/settings");
    }
  }

  isLogged() {
    let usr = this.getCurrentUser();
    if (Object.keys(usr).length === 0) {
      return false;
    } else {
      return true;
    }
  }

  checkSession() {
    return HttpService.getHeader("checkSession");
  }
}
export default new AuthService();
