import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import useFetchEventCategories from "../../hooks/useFetchEventCategories";
import { Grid } from "@mui/material";
const Footer = () => {
  const {
    event_categories,
    loading_event_categories,
    loaded_event_categories,
  } = useFetchEventCategories();
  return (
    <React.Fragment>
      <section className="partners-wrapper">
        <div className="partners">
          <div className="partner-item">
            <img src="/images/mastercard.png" alt="mastercard logo" />
          </div>
          <div className="partner-item">
            <img src="/images/mtn.jpg" alt="mtn logo" />
          </div>
          <div className="partner-item">
            <img src="/images/flutterwave.png" alt="flutterwave logo" />
          </div>
          <div className="partner-item">
            <img src="/images/paystack.png" alt="paystack logo" />
          </div>
        </div>
      </section>
      <footer className="footer">
        <div className="container">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3} sx={{ p: "10px" }}>
              <div className="flex flex-col spacer">
                <div className="footer-logo">
                  <img src="/images/logo2.png" alt="Footer Logo" />
                </div>

                <div className="under-desc">Mba Diokwu Ọ melụ ife atụ aja!</div>
                <div className="social-branders">
                  <div>Find Us:</div>
                  <ul className="cont-list">
                    <li>
                      <a href="https://twitter.com/UrumTown">
                        <i className="fa-brands fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/UrumTown">
                        <i className="fa-brands fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/UrumTown/">
                        <i className="fa-brands fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href="t.me/UrumTown">
                        <i className="fa-brands fa-telegram"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ p: "10px" }}>
              <div className="col-block">
                <ul>
                  <h4>About</h4>
                  <li>
                    <Link to="/about/history">History</Link>
                  </li>
                  <li>
                    <Link to="/about/kindreds">Kindreds</Link>
                  </li>
                  <li>
                    <Link to="/about//quarters">Quarters</Link>
                  </li>
                  <li>
                    <Link to="/about/heritage">Heritage</Link>
                  </li>
                  <li>
                    <Link to="/about/leaders">Leaders</Link>
                  </li>
                  <li>
                    <Link to="/about/committees">Committees</Link>
                  </li>
                </ul>
              </div>
            </Grid>
            <Grid item xs={12} sm={6} md={3} sx={{ p: "10px" }}>
              <div className="col-block">
                <ul>
                  <h4>Events</h4>

                  {loaded_event_categories && event_categories && (
                    <>
                      {event_categories.map((item, index) => (
                        <li key={item.title}>
                          <Link to={`/events/${item?.slug}`}>
                            {item?.title}
                          </Link>
                        </li>
                      ))}
                    </>
                  )}
                </ul>
              </div>
            </Grid>

            <Grid item xs={12} sm={6} md={3} sx={{ p: "10px" }}>
              <div className="col-block">
                <ul>
                  <h4>Resources</h4>
                  <li>
                    <Link to="/faq">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/meetings">Meetings</Link>
                  </li>
                  <li>
                    <Link to="/contact-us">Contact Us</Link>
                  </li>
                  <li>
                    <Link to="/terms">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
          <div className="terms-policy">
            <p>
              Best indigenous Radio Station, South-East of the Niger. Infusing
              Local content, reaching out to upwardly mobile and locals.
              Bridging the gap between the urban contemporary and Indigenous
              broadcasting.
            </p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
