import React from "react";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Slide from "@mui/material/Slide";
import { CircularProgress } from "@mui/material";
import HttpService from "../../../services/HttpService";
import useFetchKindreds from "../../../hooks/useFetchKindreds";
import useFetchQuaters from "../../../hooks/useFetchQuaters";
import useFetchLocationTypes from "../../../hooks/useFetchLocationTypes";

const RegisterForm = ({
  submit_handler,
  loading,
  intro_message,
}: {
  submit_handler: any;
  loading: boolean;
  intro_message: string;
}) => {
  const [form, setForm] = React.useState<any>({ agree: false });
  const handleInput = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;
    console.log(value, name);
    setForm({ ...form, [name]: value });
    console.log(form);
  };
  const [iloading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  //const [kindreds, setKindreds] = React.useState<any[]>([]);
  //const [quaters, setQuaters] = React.useState<any[]>([]);
  //const [location_types, setLocationTypes] = React.useState<any[]>([]);
  const { kindreds, loading_kindreds, loaded_kindreds } = useFetchKindreds();
  const { quaters, loading_quaters, loaded_quaters } = useFetchQuaters();
  const { location_types, loading_location_types, loaded_location_types } =
    useFetchLocationTypes();
  React.useEffect(() => {}, []);

  const [user, setUser] = React.useState<any>(null);
  const Lnk = () => {
    return (
      <div>
        Agree to our <a href="/terms"> Terms & conditions</a>
      </div>
    );
  };
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log(event.target.checked);
    const vals = event.target.checked;
    setForm({ ...form, agree: vals });
  };
  const [cur_page, setPage] = React.useState<number>(0);

  const stages: any[] = [
    "Hello, Welcome",
    "Personal Details",
    "Next of Kin Details",
    "Banking Details",
    "Login Details",
    "Preview & Submit",
  ];

  let preview: any = [];

  function capStr(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  for (let key in form) {
    let val = form[key];
    const ko = key;
    if (key === "password") {
      val = "**********";
    } else if (key === "is_ref" && form[key] === "1") {
      val = "Yes";
    } else if (key === "is_ref" && form[key] === "0") {
      val = "No";
    }
    key = key === "is_ref" ? "Were you referred?" : key;
    key = key === "ref_id" ? "Referral Code" : key;
    key = key.replace(/nok/g, "next of kin");
    const spl = key.split("_");
    const ky = capStr(spl.join(" "));
    const op = `<div class="flex flex-row"><span class="bolder">
    ${ky}</span> <span class="spacer"></span> <span>${val}
    </span></div>`;
    if (
      ko !== "password2" &&
      ko !== "action" &&
      ko !== "password_confirmation" &&
      ko !== "agree"
    ) {
      preview.push(op);
    }
  }
  return (
    <React.Fragment>
      <div>
        {intro_message && intro_message !== "" && (
          <h2 className="text-center txt-lg px20 pb30">{intro_message}</h2>
        )}
        <div className="p0">
          <Slide
            direction="up"
            in={cur_page === 0}
            timeout={{
              enter: 600,
              exit: 300,
            }}
          >
            <div className={`input ${cur_page === 0 ? "flex" : "hidden"}`}>
              <label className="textfeld-control">{"Surname"}</label>
              <input
                className={" input-text-control "}
                name={"surname"}
                type="text"
                required
                placeholder="Enter Surname..."
                onChange={handleInput}
              />
            </div>
          </Slide>
          <Slide
            direction="right"
            in={cur_page === 1}
            timeout={{
              enter: 600,
              exit: 300,
            }}
          >
            <div className={`input ${cur_page === 1 ? "flex" : "hidden"}`}>
              <label className="textfeld-control">{"Firstname"}</label>
              <input
                className={" input-text-control "}
                name={"firstname"}
                type="text"
                required
                placeholder="Enter Firstname..."
                onChange={handleInput}
              />
            </div>
          </Slide>
          <Slide
            direction="right"
            in={cur_page === 2}
            timeout={{
              enter: 600,
              exit: 300,
            }}
          >
            <div className={`input ${cur_page === 2 ? "flex" : "hidden"}`}>
              <label className="textfeld-control">{"Othernames"}</label>
              <input
                className={" input-text-control "}
                name={"othernames"}
                type="text"
                required
                placeholder="Enter Othernames..."
                onChange={handleInput}
              />
            </div>
          </Slide>

          <Slide
            direction="right"
            in={cur_page === 3}
            timeout={{
              enter: 600,
              exit: 300,
            }}
          >
            <div className={`input ${cur_page === 3 ? "flex" : "hidden"}`}>
              <label className="textfeld-control">
                {"Kindred (Umunna)"}
                <sup className="boldest red" title="This field is required!">
                  *
                </sup>
              </label>
              <select
                className={" input-text-control "}
                name={"kindred_id"}
                required
                placeholder="Select Kindred (Umunna)"
                onChange={handleInput}
              >
                <option value="">Select Kindred...</option>
                {kindreds.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </Slide>

          <Slide
            direction="right"
            in={cur_page === 4}
            timeout={{
              enter: 600,
              exit: 300,
            }}
          >
            <div className={`input ${cur_page === 4 ? "flex" : "hidden"}`}>
              <label className="textfeld-control">
                {"Quarter"}
                <sup className="boldest red" title="This field is required!">
                  *
                </sup>
              </label>
              <select
                className={" input-text-control "}
                name={"quarter_id"}
                required
                placeholder="Quater"
                onChange={handleInput}
              >
                <option value="">Select Quarter</option>
                {quaters.map((item) => (
                  <option value={item.id} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          </Slide>

          <Slide
            direction="right"
            in={cur_page === 5}
            timeout={{
              enter: 600,
              exit: 300,
            }}
          >
            <div className={`input ${cur_page === 5 ? "flex" : "hidden"}`}>
              <label className="textfeld-control">
                {"Residence Type"}
                <sup className="boldest red" title="This field is required!">
                  *
                </sup>
              </label>
              <select
                className={" input-text-control "}
                name={"location_type_id"}
                required
                placeholder="Residence Type"
                onChange={handleInput}
              >
                <option value="">Select Residence Type...</option>
                {location_types.map((item) => (
                  <option value={item.id} key={item.title}>
                    {item.title}
                  </option>
                ))}
              </select>
            </div>
          </Slide>

          <Slide
            direction="right"
            in={cur_page === 6}
            timeout={{
              enter: 600,
              exit: 300,
            }}
          >
            <div className={`input ${cur_page === 6 ? "flex" : "hidden"}`}>
              <label className="textfeld-control">{"Phone Number"}</label>
              <input
                className={" input-text-control "}
                name={"phone"}
                type="text"
                required
                placeholder="Enter Phone Number..."
                onChange={handleInput}
              />
            </div>
          </Slide>

          <Slide
            direction="right"
            in={cur_page === 7}
            timeout={{
              enter: 600,
              exit: 300,
            }}
          >
            <div className={`input ${cur_page === 7 ? "flex" : "hidden"}`}>
              <label className="textfeld-control">
                {"Email Addres"}
                <sup className="boldest red" title="This field is required!">
                  *
                </sup>
              </label>
              <input
                className={" input-text-control "}
                name={"email"}
                type="text"
                required
                placeholder="Enter Email Address"
                onChange={handleInput}
              />
            </div>
          </Slide>

          <Slide
            direction="right"
            in={cur_page === 8}
            timeout={{
              enter: 600,
              exit: 300,
            }}
          >
            <div className={`input ${cur_page === 8 ? "flex" : "hidden"}`}>
              <label className="textfeld-control">
                {"Password"}
                <sup className="boldest red" title="This field is required!">
                  *
                </sup>
              </label>
              <input
                className={" input-text-control "}
                name={"password"}
                type="password"
                required
                placeholder="Enter Password..."
                onChange={handleInput}
              />
            </div>
          </Slide>

          <Slide
            direction="right"
            in={cur_page === 9}
            timeout={{
              enter: 600,
              exit: 300,
            }}
          >
            <div className={`input ${cur_page === 9 ? "flex" : "hidden"}`}>
              <label className="textfeld-control">
                {"Confirm Password"}
                <sup className="boldest red" title="This field is required!">
                  *
                </sup>
              </label>
              <input
                className={" input-text-control "}
                name={"password_confirmation"}
                type="password"
                required
                placeholder="Confirm Password..."
                onChange={handleInput}
              />
            </div>
          </Slide>
        </div>
        {/**Stage 4 Ends */}
        <Slide
          direction="up"
          in={cur_page === 10}
          timeout={{
            enter: 600,
            exit: 300,
          }}
        >
          <div
            className={`input mt0 mb0 ${cur_page === 10 ? "block" : "hidden"}`}
          >
            <div className="py0 flex flex-col">
              {preview.map((item: any, index: number) => (
                <div
                  className="prev-line"
                  key={index}
                  dangerouslySetInnerHTML={{ __html: item }}
                ></div>
              ))}
            </div>
          </div>
        </Slide>
        {/**Stage 4 Ends */}
        <div className="flex flex-row pb10">
          <span>
            {cur_page > 0 && cur_page < 10 && (
              <Button disabled={loading} onClick={() => setPage(cur_page - 1)}>
                <i className="fas fa-chevron-left"></i> &nbsp; Back
              </Button>
            )}
          </span>
          <span className="spacer"></span>
          <span>
            {cur_page < 10 && (
              <Button disabled={loading} onClick={() => setPage(cur_page + 1)}>
                Next &nbsp; <i className="fas fa-chevron-right"></i>
              </Button>
            )}
          </span>
        </div>
        {cur_page === 10 && (
          <div className="flex align-items-center pxy10 bordered border-radius-5 ">
            <button
              className="button-link txt-normal px0 color-error"
              onClick={() => setPage(0)}
            >
              <i className="fas fa-edit"></i> EDIT FORM
            </button>
            <span className="spacer"></span>
            <button
              className="button-link txt-normal px0 color-info"
              onClick={() => {
                setForm({});
                setPage(0);
              }}
            >
              <i className="fas fa-refresh"></i> RESET FORM
            </button>
          </div>
        )}
        <Slide
          direction="up"
          in={cur_page === 10}
          timeout={{
            enter: 1900,
            exit: 300,
          }}
        >
          <div className={`input mt0 ${cur_page === 10 ? "block" : "hidden"}`}>
            <div className="pb20">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={form.agree}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={<Lnk />}
                />
              </FormGroup>
            </div>
            <div className="">
              <Button
                disabled={loading || !form.agree}
                onClick={() => submit_handler(form)}
                variant="contained"
                color="primary"
                size="large"
                fullWidth
              >
                <span>{loading && <CircularProgress size={"25px"} />}</span>
                <span className="px10">
                  {loading ? "Working..." : "Submit"}
                </span>
              </Button>
            </div>
          </div>
        </Slide>
        {/*  <div className="pb20">
           <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={form.agree}
                  onChange={handleChange}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={<Lnk />}
            />
          </FormGroup>

       
        </div> */}
        {/*   <div className="flex flex-row align-items-center">
          <Button
            onClick={() => submit_handler(form)}
            size="large"
            disabled={loading}
            variant="contained"
            type="submit"
          >
            {loading ? "Working..." : "Register"}
          </Button>
          <span className="spacer"></span>
          <span className="pl5">
            <Link to={"/forgot-password"}>Forgot password?</Link>
          </span>
        </div> */}
      </div>
      <div className="pt10 pb0 text-center">
        Already an have account? <Link to="/login">Login here</Link>
      </div>
    </React.Fragment>
  );
};

export default RegisterForm;
