import React from "react";
import Button from "@mui/material/Button";
import CustomModal from "../../templates/CustomModal";
import HttpService from "../../../services/HttpService";
import { Avatar, IconButton } from "@mui/material";
import { CameraAltOutlined } from "@mui/icons-material";
import AuthService from "../../../services/AuthService";
const UploadPicture = (props: any) => {
  console.log(props);
  let { user, onopen, onClose, handler } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [data, setData] = React.useState<any>(null);
  const modalClose = () => setModal({ ...modal_data, onopen: false });
  const [modal_data, setModal] = React.useState<any>({
    onopen: false,
    onclose: modalClose,
  });

  const dts = user?.dob ? new Date(user?.dob) : new Date();
  const [fetched, setFetched] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (user) {
      setData({
        ...user,
        dob: dts,
      });
      setFetched(true);
    }
  }, []);

  const [preview_image, setPreview] = React.useState<any>(null);
  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);

  const handleFileChange = (e: any) => {
    console.log(e.target.name, e.target.files);

    const formData = new FormData();
    let fname = e.target.name;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };
  const submit_handler = () => {
    console.log(data);
    setLoading(true);
    setLoaded(false);
    data.mode = "upload_picture";
    const formData = new FormData();
    formData.append("picture", file);
    formData.append("is_new_file", new_file);
    formData.append("user_id", user.id);
    HttpService.postHeader("change_picture", formData)
      .then(
        (result) => {
          console.log(result);
          const severity =
            result.status === 1
              ? "success"
              : result.status === 0
              ? "error"
              : "info";
          setModal({
            ...modal_data,
            onopen: true,
            message: result.message,
            severity: severity,
            onclose: modalClose,
          });
          console.log("::|", result);
          if (result.status === 1) {
            AuthService.updateUser(result.user);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  };

  return (
    <React.Fragment>
      <div className="pxy20">
        <h3 className="mb10">
          {" "}
          <i className="fas fa-image"></i> Upload Picture
        </h3>

        <div className="avatar-pane flex flex-column justify-content-center pxy10">
          <Avatar src={preview_image} sx={{ width: 200, height: 200 }}></Avatar>
        </div>
        <div className="avatar-pane flex flex-column justify-content-center pxy10">
          {/*    {preview_image && (
            <div className="image_preview">
              <img className="" src={preview_image} alt="preview Image" />
            </div>
          )} */}
          <div className={loading ? " input iconed " : " input "}>
            <label>Select Picture</label>
            <input
              type="file"
              className="input-form-control"
              name="file"
              onChange={handleFileChange}
            />
          </div>
        </div>

        <div className="flex flex-row align-items-center">
          <span className="">
            <Button
              onClick={submit_handler}
              size="large"
              disabled={loading}
              variant="contained"
              type="submit"
            >
              {loading ? "Working..." : "Update Picture"}
            </Button>
          </span>

          <span className="spacer"></span>
          <span className="pl5">
            <Button
              color="warning"
              onClick={() => props.handler(false)}
              size="large"
            >
              Exit
            </Button>
          </span>
        </div>
      </div>
      <CustomModal data={modal_data} />
    </React.Fragment>
  );
};

export default UploadPicture;
