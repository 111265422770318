import React from "react";
import { Routes, Route } from "react-router-dom";

const ClearanceDetail = (props: any) => {
  return (
    <React.Fragment>
      <section></section>
    </React.Fragment>
  );
};

export default ClearanceDetail;
