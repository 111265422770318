import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Close from "@mui/icons-material/Close";
import { DialogActions, Divider } from "@mui/material";

const TransitionUp = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function CustomModal(props: any) {
  const modal = props.data;
  const severity = modal.severity ? modal.severity : "info";
  console.log(modal);
  const hider = modal.hideduration ? modal.hideduration : 3000;
  if (modal.hideduration) {
    setTimeout(() => {
      modal.onclose();
    }, hider);
  }
  const tmx = new Date().getTime();
  const createMarkup = (text: any) => {
    return { __html: text };
  };
  const handleClose = () => {
    modal.onclose();
  };

  const status_bg = modal.severity ? "bg-" + modal.severity : "bg-info";
  const status_color = modal.severity
    ? "color-" + modal.severity
    : "color-info";
  const status_icon =
    modal.severity === "success"
      ? "check-circle"
      : modal.severity === "error"
      ? "exclamation-triangle"
      : "info-circle";
  return (
    <>
      <Dialog
        open={modal.onopen}
        aria-labelledby={"Me"}
        id={"md-" + tmx}
        TransitionComponent={TransitionUp}
      >
        <div className={`head-area ${status_bg}`}>
          <i className={`fas fa-${status_icon}`}></i>
        </div>
        <DialogContent sx={{ p: "0" }}>
          <div className="alert-container">
            <div className={`message-area ${status_color}`}>
              {modal.message && (
                <div dangerouslySetInnerHTML={createMarkup(modal.message)} />
              )}
              {modal.formatted_message && (
                <div
                  dangerouslySetInnerHTML={createMarkup(
                    modal.formatted_message
                  )}
                />
              )}
            </div>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            alignContent: "stretch",
            p: "0",
          }}
        >
          <div className="button-area">
            <Button
              fullWidth
              size="large"
              onClick={handleClose}
              sx={{ paddingY: "13px", color: "#888" }}
            >
              <span className="px10">EXIT</span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
