import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MeetingProvider,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import { Button, Grid } from "@mui/material";
import HttpService from "../../../services/HttpService";
import Header from "../../Header/Header";
import PageIntro from "../PageIntro/PageIntro";
import PlaceHolder from "../../templates/PlaceHolder";
import Footer from "../../Footer/Footer";
import LiveListTemplate from "../../templates/LiveListTemplate";
import LiveLayout from "./LiveLayout";

const LiveList = () => {
  const [lives, setLives] = React.useState<any[]>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [page_data, setPageDate] = React.useState<any>({});

  React.useEffect(() => {
    setPageDate({
      ...page_data,
      page_slug: "",
      page_title: "Live Streams",
      page_summary: "All Live Streams",
    });
    getLives();
  }, []);

  const getLives = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ table: "events" }, "all_lives")
      .then(
        (res) => {
          console.log(res);
          if (res.status === 1) {
            setLives(res.data);
          }
        },
        (err) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return (
    <React.Fragment>
      <LiveLayout page_data={page_data}>
        {loaded && (
          <LiveListTemplate
            loading={loading}
            loaded={loaded}
            lives={lives}
            max_grid={4}
          />
        )}
        {loading && <PlaceHolder type="articles" />}
      </LiveLayout>
    </React.Fragment>
  );
};
export default LiveList;
