import React from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumbs,
  Card,
  Grid,
  Icon,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../../services/HttpService";
import CustomModal from "../../../templates/CustomModal";
import useFetchPage from "../../../../hooks/useFetchPage";
import { Delete, Edit, Settings } from "@mui/icons-material";
import useFetchKindreds from "../../../../hooks/useFetchKindreds";

const ListKindreds = () => {
  let navigate = useNavigate();
  const params = useParams();
  console.log(params);
  const isParam = params.pageId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  const { page, loading_page, loaded_page } = useFetchPage({
    slug: "kindreds",
  });
  const { kindreds, loading_kindreds, loaded_kindreds } = useFetchKindreds();

  let [desc, setDesc] = React.useState("");
  let [title, setTitle] = React.useState("");
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };

  React.useEffect(() => {
    setTitle(page.title);
    setDesc(page.description);
  }, [page]);
  const onHtmlChange = (e: any) => {
    setDesc(e.target.value);
  };

  const handleSubmit = () => {
    setLoading(true);
    setLoaded(false);
    console.log("SUBMITTING");
    HttpService.post(
      {
        title: title,
        description: desc,
        id: page?.id,
      },
      "edit_page"
    )
      .then((resp) => {
        console.log(resp);
        if (resp.status === 1) {
          setModal({
            ...modal,
            onopen: true,
            severity: "success",
            message: resp.message,
          });
        } else {
          setModal({
            ...modal,
            onopen: true,
            severity: "error",
            message: resp.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setModal({
          ...modal,
          onopen: true,
          severity: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const togEdit = (i: any) => {
    return navigate(`/admin/about/kindreds/e/${i.id}`);
  };

  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "Edit event",
    loading_message: null,
    loading: false,
  });

  const closeDelModal = () => {
    setDelModal({ ...del_modal, onopen: false });
  };
  const [del_modal, setDelModal] = React.useState<any>({
    onopen: false,
    onclose: closeDelModal,
    title: "Delete event",
  });
  const launchDelete = (item: any) => {
    setDelModal({
      ...del_modal,
      id: item.id,
      loading_message: null,
      loading: false,
      message:
        "Are you sure you want to <strong><u>remove</u></strong> this event?",
      onopen: true,
      onclose: closeDelModal,
      onaccept: delete_event,
    });
  };
  const delete_event = (item: any) => {};
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/about">About</Link>
            <span>Kindreds</span>
          </Breadcrumbs>
          <h1> Kindreds</h1>
        </div>

        <section className="pxy20">
          <Grid container spacing={1.5}>
            {kindreds.map((item) => (
              <Grid item xs={12} sm={6} md={4} key={item.id}>
                <div className="bga ">
                  <ListItem
                    secondaryAction={
                      <span>
                        <Tooltip title="Edit event">
                          <IconButton onClick={() => togEdit(item)}>
                            <Edit />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Remove event">
                          <IconButton
                            color="warning"
                            onClick={() => launchDelete(item)}
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </span>
                    }
                  >
                    <ListItemIcon>
                      <Icon>
                        <Settings />
                      </Icon>
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <h2 style={{ lineHeight: "1.2" }}>
                          <Link to={`/admin/about/kindreds/p/${item.id}`}>
                            {item.name}
                          </Link>
                        </h2>
                      }
                    ></ListItemText>
                  </ListItem>
                </div>
              </Grid>
            ))}
          </Grid>
        </section>
        <section className="page-detail-container">
          <div className="pxy20">
            <div className={loading_page ? " input iconed " : " input "}>
              <label>Page title</label>
              <input
                type="text"
                className="input-form-control"
                name="name"
                disabled={loading_page || loading}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder={"page Title"}
              />
            </div>

            <div className="mb10">
              <DefaultEditor
                className="input-form-control"
                value={desc}
                placeholder="Page Detail"
                disabled={loading_page || loading}
                onChange={onHtmlChange}
              />
            </div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={loading || loading_page}
              onClick={handleSubmit}
            >
              {loading ? "Working..." : " Edit page "}
            </Button>
          </div>
        </section>
      </Card>
    </div>
  );
};

export default ListKindreds;
