import React from "react";
import { Link } from "react-router-dom";
import { Breadcrumbs } from "@mui/material";
import "./PageIntro.css";
const PageIntro = ({
  title,
  summary,
  sub_text,
  sub_link,
  svg,
  loading_page,
}: {
  title?: any;
  summary?: string;
  sub_text?: string;
  sub_link?: string;
  svg?: string;
  loading_page?: boolean;
}) => {
  const default_bg = "/images/girly.png";
  return (<React.Fragment>
        
    <div className="page-top-content">
      <div className="page-intro-div">
        <h1>{title}</h1>
        <div className="py10">
          <Breadcrumbs
            aria-label="breadcrumb"
            sx={{
              width: "100%",
            }}
          >
            <Link to="/">Home</Link>
            {sub_link && sub_text && <Link to={sub_link}>{sub_text}</Link>}
            <span>{loading_page ? "Loading..." : title}</span>
          </Breadcrumbs>
        </div>
      </div>
      {/* <div
        className="illustration-container"
        style={{ backgroundImage: `url(${svg || default_bg})` }}
      ></div> */}
    </div>
 </React.Fragment> );
};

export default React.memo(PageIntro);
