import React from "react";
import { useNavigate, Routes, Route } from "react-router-dom";
import MessagesList from "./MessagesList";
import MessagesNew from "./MessagesNew";
import MessagesDetails from "./MessagesDetails";
import MessagesReply from "./MessagesReply";

const Messages = (props: any) => {
  const navigate = useNavigate();

  const launchNew = () => {
    return navigate(`/account/Messages/a/new`);
  };
  const launchReply = (id: any) => {
    return navigate(`/account/Messages/r/new/${id}`);
  };
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<MessagesList launchNew={launchNew} />} />
        <Route path="/" element={<MessagesList launchNew={launchNew} />} />
        <Route path="/p/:messagesId" element={<MessagesDetails />} />
        <Route path="/a/new" element={<MessagesNew />} />
        <Route
          path="/r/:messagesId"
          element={<MessagesReply launchNew={launchReply} />}
        />
      </Routes>
    </React.Fragment>
  );
};

export default Messages;
