import React from "react";
import SeoModule from "../../../services/SeoModule";
import RegisterModule from "./RegisterModule";
import { Link } from "react-router-dom";

const Register = () => {
  return (
    <React.Fragment>
      <SeoModule
        title=" Register - CyprexCBT"
        description="Register"
        name="CyprexCBT"
        type="Crypto Investment"
      />
      <section className="page-main">
        <div className="home-overlay"></div>
        <div className="register-space">
          <div className="auth-logo-space">
            <Link className="logo" to="/">
              <img src={`/images/logo2.png`} alt="CyprexCBT Logo" />
            </Link>
          </div>
        </div>

        <div className="login-cover">
          <div className="login-pane">
            <RegisterModule />
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Register;
