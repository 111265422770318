import React from "react";
import { Routes, Route } from "react-router-dom";
import MediaHome from "./MediaHome";
import Videos from "./Videos";
import Audios from "./Audios";
import Letters from "./Letters";
const Media = () => {
  return (
    <React.Fragment>
      <section className="bg-diff">
        <Routes>
          <Route path="" element={<MediaHome />} />
          <Route path="/" element={<MediaHome />} />
          <Route path="/videos" element={<Videos />} />
          <Route path="/audios" element={<Audios />} />
          <Route path="/letters" element={<Letters />} />
        </Routes>
      </section>
    </React.Fragment>
  );
};

export default Media;
