import { Breadcrumbs, Button, Card } from "@mui/material";
import React from "react";
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import AuthService from "../../../services/AuthService";
import MeetingsListTemplate from "../../templates/MeetingsListTemplate";
import PlaceHolder from "../../templates/PlaceHolder";
const usr = AuthService.getCurrentUser();
const MeetingsList = () => {
  const [meetings, setMeetings] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    getmeetings();
  }, []);
  const getmeetings = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({}, "all_meetings")
      .then(
        (res) => {
          console.log(res);
          if (res.status === 1) {
            setMeetings(res.data);
          }
        },
        (err) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const togView = (index: number, state: boolean) => {
    console.log(index, state);
    const mutd = [...meetings];
    const item = (mutd[index]["is_togged"] = !mutd[index]["is_togged"]);
    setMeetings(mutd);
  };

  return (
    <React.Fragment>
      <section className="account-page-container">
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/account">Dashboard</Link>
              <span>Meetings</span>
            </Breadcrumbs>
            <h1>Meetings</h1>
          </div>
          <section className="page-detail-container">
            <div className="flex flex-row border-bottom align-items-center pxy10">
              <span className="spacer"></span>
              <span>
                <Button variant="outlined" size="small">
                  Pay Clearance
                </Button>
              </span>
            </div>
          </section>
        </Card>
        <section className="container py20">
          <div className="pxy20">
            {loaded && (
              <MeetingsListTemplate
                loading={loading}
                loaded={loaded}
                meetings={meetings}
                max_grid={4}
              />
            )}
            {loading && <PlaceHolder type="articles" />}
          </div>
        </section>
      </section>
    </React.Fragment>
  );
};

export default MeetingsList;
