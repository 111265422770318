import React from "react";
import { Link } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import { DefaultEditor } from "react-simple-wysiwyg";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import useFetchPage from "../../../hooks/useFetchPage";
import * as processHtml from "../../../services/processHtml";
import SeoModule from "../../../services/SeoModule";
import PageIntro from "../PageIntro/PageIntro";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";

const Contact = () => {
  const [loading, setLoading] = React.useState<boolean>(false);
  const [loaded, setLoaded] = React.useState<boolean>(false);
  const [content, setContent] = React.useState<string>("");
  const [meta, setMeta] = React.useState<any>({});
  const onToastClose = () => {
    setToast({ onopen: false, onclose: onToastClose });
  };
  const [toast, setToast] = React.useState<any>({
    onopen: false,
    onclose: onToastClose,
    message: "",
  });

  const { decodeHtml, truncateWord } = processHtml;
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { page, loaded_page, loading_page } = useFetchPage({
    slug: "contact-us",
  });
  const handleInput = React.useCallback(
    (event: any) => {
      const targ = event.target;
      const name = targ.name;
      const value = targ.value;
      console.log(name, value);
      setMeta({ ...meta, [name]: value });
      console.log(meta);
    },
    [meta]
  );

  const onHtmlChange = (e: any) => {
    setContent(e.target.value);
    console.log(content);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const obj: any = { ...meta, message: content, mode: "contact-us" };
    console.log(obj);
    setLoading(true);
    setLoaded(false);
    HttpService.postForm("contact-us", obj)
      .then(
        (resp) => {
          console.log(resp);
          setToast({ ...toast, onopen: true, message: resp.message });
          if (resp.status === 1) {
            setTimeout(() => {
              setToast({ ...toast, onopen: false });
            }, 3000);
          }
        },
        (error) => {
          setToast({ ...toast, onopen: true, message: error.message });
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //postTicket
  };

  return (
    <React.Fragment>
      <SeoModule
        title="Contact Us - CyprexCBT"
        description="Fintech: banking, crypto, investments"
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
        <PageIntro
          title={page.title}
          summary={page.title}
          loading_page={loading_page}
        />
      </section>
      <section className="dashboard-pane">
        <div className="container">
          <Card sx={{ borderRadius: "0" }}>
            <div className="px20">
              <div
                dangerouslySetInnerHTML={{
                  __html: decodeHtml(page.decription),
                }}
              ></div>
            </div>
          </Card>
        </div>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Contact;
