import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, Card, Grid } from "@mui/material";
import HttpService from "../../../services/HttpService";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import ListItemIcon from "@mui/material/ListItemIcon";
import CustomModal from "../../templates/CustomModal";
import DurationPipe from "../../../pipes/DurationPipe";
import Currency from "../../../pipes/Currency";
import {
  AddModerator,
  CameraAltOutlined,
  CommentOutlined,
  DirectionsOutlined,
  MoneyOutlined,
  TimerOutlined,
  VideoCameraFrontOutlined,
} from "@mui/icons-material";

import PlaceHolder from "../../templates/PlaceHolder";

const PenaltyDetail = () => {
  const params = useParams();
  const isParam = params.mId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [event, setEvent] = React.useState<any>({ designation: "0" });

  const [preview, setPreview] = React.useState<any>("");
  const [title, setTitle] = React.useState<any>("");
  const [venue, setVenue] = React.useState<any>("");
  const [start_time, setStartTime] = React.useState<any>("");
  const [end_time, setEndTime] = React.useState<any>("");
  const [description, setDesc] = React.useState<any>("");
  let [id, setId] = React.useState<any>(0);

  React.useEffect(() => {
    getevent(params.mId);
  }, [params?.mId]);

  const getevent = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ id: id }, "event_details")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setEvent(result.data);
            setTitle(result.data.title);
            setVenue(result.data.venue);
            setStartTime(result.data.start_time);
            setEndTime(result.data.end_time);
            setDesc(result.data.description);
            setId(result.data.id);
            setPreview(process.env.REACT_APP_SERVER_ROOT + result.data.picture);
          } else {
            setEvent({});
          }
        },
        (error) => {
          setEvent({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New event",
  });

  const [value, setValue] = React.useState("one");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/events">Events</Link>
            <span>{title || "--"}</span>
          </Breadcrumbs>
        </div>
        <section className="page-detail-container">
          <section className="container py20">
            <Card sx={{ borderRadius: "0" }}>
              {loaded && (
                <Card sx={{ borderRadius: "0" }}>
                  <div className="page-head bga">
                    <div className="flex flex-row-resp align-items-center">
                      <div className="inline-block pxy20 spacer">
                        <Breadcrumbs
                          aria-label="breadcrumb"
                          sx={{
                            width: "100%",
                          }}
                        >
                          <Link to="/">Home</Link>
                          <Link to="/events">Events</Link>
                        </Breadcrumbs>
                        <h2 className="mt10">{event.title}</h2>
                        <div>
                          <i className="fas fa-timer"></i>
                          <DurationPipe
                            start={event.start_date * 1000}
                            end={event.end_date * 1000}
                          />
                        </div>
                      </div>

                      <span className="px20 sm-hide-inline">
                        <Avatar
                          variant="circular"
                          sx={{
                            width: 120,
                            height: 120,
                          }}
                          alt={`${event.title} `}
                          src={
                            process.env.REACT_APP_SERVER_FILES_DOMAIN +
                            event.thumb
                          }
                        />
                      </span>
                    </div>
                  </div>

                  <div className="pxy0 bg-differ">
                    <div className=" pxy15">
                      <div className="relative">
                        {/*<div className="highlighted-container">
                      <div className="highlight-banner">
                        <img
                          src={
                            process.env.REACT_APP_SERVER_FILES_DOMAIN +
                            event.photo
                          }
                          alt="event banner"
                        />
                      </div>
                    </div>
                    <Divider />
                   
                    <div className="highlight-details">
                      <div className="flex event-meta align-items-center">
                        <span>
                          <i className="fas fa-calendar"></i>{" "}
                          <DurationPipe
                            start={event.start_date * 1000}
                            end={event.end_date * 1000}
                          />
                        </span>

                        <span className="txt px5"></span>
                        <span className="spacer"></span>
                        <span className="">
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={launchBooking}
                            size="large"
                            color="warning"
                          >
                            REGISTER
                          </Button>
                        </span>
                      </div>
                    </div>*/}
                      </div>
                    </div>

                    <Divider />
                    <Card sx={{ m: "0", p: "0" }}>
                      <Grid
                        container
                        rowSpacing={0}
                        columnSpacing={{ xs: 0, sm: 2, md: 2 }}
                        sx={{
                          display: "flex",
                          backgroundColor: "#fafafa",
                        }}
                      >
                        <Grid item xs={12} sm={6} md={4}>
                          <ListItem divider>
                            <ListItemIcon>
                              <MoneyOutlined sx={{ fontSize: "40px" }} />
                            </ListItemIcon>
                            <ListItemText>
                              <div className="bolder">Physical Attendance</div>
                              <div className="">
                                <Currency value={event.physical_price} />
                              </div>
                            </ListItemText>
                          </ListItem>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <ListItem divider>
                            <ListItemIcon>
                              <DirectionsOutlined sx={{ fontSize: "40px" }} />
                            </ListItemIcon>
                            <ListItemText>
                              <div className="bolder">Venue Directions</div>
                              <div>
                                <a href="/">Open Map</a>
                              </div>
                            </ListItemText>
                          </ListItem>
                        </Grid>

                        <Grid item xs={12} sm={6} md={4}>
                          <ListItem divider>
                            <ListItemIcon>
                              <TimerOutlined sx={{ fontSize: "40px" }} />
                            </ListItemIcon>
                            <ListItemText>
                              <div className="bolder">Event Duration</div>
                              <div>
                                <DurationPipe
                                  start={event.start_date * 1000}
                                  end={event.end_date * 1000}
                                />
                              </div>
                            </ListItemText>
                          </ListItem>
                        </Grid>
                      </Grid>
                      <Divider />
                      <Box sx={{ width: "100%" }}>
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="wrapped label tabs example"
                        >
                          <Tab
                            value={0}
                            label={
                              <span className="flex align-items-center">
                                <CameraAltOutlined />
                                <h3 className="pl5 boldest">Pictures</h3>
                              </span>
                            }
                            wrapped
                          />
                          <Tab
                            value={1}
                            label={
                              <span className="flex align-items-center">
                                <VideoCameraFrontOutlined />
                                <h3 className="pl5 boldest">Videos</h3>
                              </span>
                            }
                          />
                          <Tab
                            value={2}
                            label={
                              <span className="flex align-items-center">
                                <CommentOutlined />
                                <h3 className="pl5 boldest">Comments</h3>
                              </span>
                            }
                          />
                        </Tabs>
                      </Box>
                    </Card>
                  </div>
                </Card>
              )}
              {loading && <PlaceHolder type="event-detail" />}
            </Card>
          </section>
        </section>
      </Card>
    </div>
  );
};

export default PenaltyDetail;
