import React from "react";
import { Link } from "react-router-dom";
import AuthService from "../../../services/AuthService";
import Grid from "@mui/material/Grid";
import {
  AccountBox,
  CardMembershipOutlined,
  CategoryOutlined,
  CommentOutlined,
  DashboardOutlined,
  Event,
  GroupWorkSharp,
  ListOutlined,
  LiveTv,
  MeetingRoomOutlined,
  MessageSharp,
  PaymentsOutlined,
} from "@mui/icons-material";
import { Divider } from "@mui/material";
import HttpService from "../../../services/HttpService";

const usr = AuthService.getCurrentUser();

const Dashboard = () => {
  const [clearance, setClearance] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    getclearance();
    console.log(usr);
  }, []);
  const getclearance = () => {
    setLoading(true);
    setLoaded(false);
    let dta = { uid: usr?.id };
    HttpService.postHeader("show_clearance", dta)
      .then(
        (res) => {
          console.log("clr::", res);
          if (res.status === 1) {
            setClearance(res.data);
          }
        },
        (err) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const pages: any[] = [
    {
      path: "membership",
      title: "Membership",
      icon: <CardMembershipOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "meetings",
      title: "Meetings",
      icon: <MeetingRoomOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "events",
      title: "Events",
      icon: <Event />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "transactions",
      title: "Transactions",
      icon: <PaymentsOutlined />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
    {
      path: "messages",
      title: "Messages",
      icon: <MessageSharp />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },

    {
      path: "settings",
      title: "Account Settings",
      icon: <AccountBox />,
      navItem: true,
      data: { mustAuth: true, isAdmin: false, showSideNav: false },
    },
  ];

  return (
    <React.Fragment>
      <section className="dashboard-pane">
        {/*     <Grid item xs={12} sm={6} md={4} key={item.path}>
                  <Link className="grid-card g1" to={`/account/${item.path}`}>
                    <div className="icon-pane">
                      {item.icon}
                    </div>
                    <div className="pane-title">{item.title}</div>
                  </Link>
                </Grid> */}
        <div className="z-high">
          <div className="home-get-grid z-high">
            <Grid container spacing={1.5}>
              {pages.map((item) => (
                <Grid item xs={12} sm={6} md={4} key={item.path}>
                  <Link
                    className="grid-card bga flex flex-col"
                    to={`/account/${item.path}`}
                  >
                    <div className="flex flex-row align-items-center">
                      <span className="time-icon">{item.icon}</span>
                    </div>
                    <div className="spacer">
                      <h2 className="spacer text-right ucap lh-1">
                        {item.title}
                      </h2>
                      <div className="spacer txt-sm text-right ucap">
                        per $XRV
                      </div>
                    </div>
                  </Link>
                  <span className="py10">
                    <Divider />
                  </span>
                  <span className="spacer"></span>
                  <div className="flex flex-row bit-col-container">
                    <div className="col-bit"></div>
                    <div className="col-bit"></div>
                    <div className="col-bit"></div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Dashboard;
