import { Button } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import Header from "../../Header/Header";
const HomeMain = () => {
  const [portfolio, setPortfolio] = React.useState<any[]>([]);
  const [result_loaded, setResultLoaded] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [tab, setTab] = React.useState<number>(0);
  const styles = {
    backgroundImage:
      tab === 0 ? "url(/images/main-home.webp)" : "url(/images/girly.png)",

    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const tabs: any[] = [
    { id: 0, title: "HIRE US" },
    { id: 1, title: "LEARN SKILLS" },
  ];
  return (
    <React.Fragment>
      {/*  style={styles} */}
      <section className="home-main home-cover">
        <div className="home-overlay"></div>
        <Header base_page="home" />

        <div className="home-content">
          <div className="home-intro-div">
            <h1>
              {/* 
              You are welcome!
              <br /> */}
              <span className="em">This is</span>{" "}
              <span className="colored">Dianaokwu</span>
              <br /> <em>(Diokwu Ọ melụ ife atụ aja!)</em>
            </h1>
            <p>
              Come let us help you ace your JAMB, WAEC and NECO CBT tests in one
              sitting
            </p>
            <div className="hcta">
              <Link to="/history">LEARN MORE</Link>
            </div>
          </div>
          <div className="illustration-container"></div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default React.memo(HomeMain);
