import React from "react";
import { Routes, Route } from "react-router-dom";
import AboutHome from "./AboutHome";
import Kindreds from "./Kindreds/Kindreds";
import Quarters from "./Quarters";
import History from "./History";
import Leaaders from "./Leaders";
import Heritage from "./Heritage";

const About = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<AboutHome />} />
        <Route path="/kindreds/*" element={<Kindreds />} />
        <Route path="/quarters" element={<Quarters />} />
        <Route path="/history" element={<History />} />
        <Route path="/heritage" element={<Heritage />} />
        <Route path="/leaders" element={<Leaaders />} />
      </Routes>
    </React.Fragment>
  );
};

export default About;
