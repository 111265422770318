import React from "react";
import HttpService from "../services/HttpService";

const useFetchKindreds = () => {
  const [kindreds, setKindreds] = React.useState<any[]>([]);
  const [loading_kindreds, setLoading] = React.useState(false);
  const [loaded_kindreds, setLoaded] = React.useState(false);

  React.useEffect(() => {
    fetch_kindred();
  }, []);

  const fetch_kindred = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("list_kindreds", {})
      .then(
        (res) => {
          if (res.status === 1) {
            setKindreds(res.data);
          }
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return { kindreds, loading_kindreds, loaded_kindreds };
};

export default useFetchKindreds;
