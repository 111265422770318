import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  MeetingProvider,
  useMeeting,
  useParticipant,
} from "@videosdk.live/react-sdk";
import ReactPlayer from "react-player";
import { Card, Grid, LinearProgress } from "@mui/material";
import HttpService from "../../../services/HttpService";
import { useParams } from "react-router-dom";
import * as processHtml from "../../../services/processHtml";
import Header from "../../Header/Header";

//

const LivePage = () => {
  console.log("LIVE PAGE DETAILS");
  const authToken = process.env.REACT_APP_VIDEOSDK_TOKEN;
  const params = useParams();
  const { decodeHtml } = processHtml;
  const isParam = params.lId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [live, setLive] = React.useState<any>({ token: authToken });
  const [isStreaming, setStreaming] = useState(false);
  const [streamEnded, setStreamEnded] = useState(false);
  const [live_started, setLiveStarted] = useState(false);
  let [id, setId] = React.useState<any>(0);
  let [room, setRoom] = React.useState<any>(null);

  React.useEffect(() => {
    getLive(params.lId);
  }, [params?.lId]);

  const getLive = (id: any) => {
    setLoading(true);
    setLoaded(false);
    console.log("stream_id::", id);
    HttpService.post({ id: id }, "live_details")
      .then(
        (result) => {
          setLoading(false);
          console.log("LIVE::::", result);
          if (result.status === 1) {
            const rs = result.data;
            setLive({ ...live, ...rs });
            setStreaming(true);
            getRoom(rs.stream_id, live?.token);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  React.useEffect(() => {
    if (live?.meetId && live?.token) {
      setStreaming(true);
    }
  }, [live?.meetId, live?.token]);

  const getRoom = (rid: string, token: string) => {
    HttpService.getRoom(rid, token)
      .then(
        (res) => {
          console.log("room res::", res);
          setRoom(res);
        },
        (err) => {
          console.log("room err::", err);
        }
      )
      .finally(() => {
        console.log("room::", room);
      });
  };
  return (
    <React.Fragment>
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
      </section>

      <section className="container py20">
        <Card sx={{ borderRadius: "0" }}>
          {isStreaming && (
            <MeetingProvider
              config={{
                meetingId: live?.stream_id,
                micEnabled: true,
                webcamEnabled: true,
                name: "Joseph's Org",
                debugMode: true,
              }}
              token={live?.token}
              reinitialiseMeetingOnConfigChange={true}
              joinWithoutUserInteraction={live_started}
            >
              <MeetingView />
            </MeetingProvider>
          )}
          {!isStreaming && (
            <div className="pxy30">
              <div className="py20">
                <LinearProgress />
              </div>
              <div>
                <h2 className="text-center">Loading Live Stream...</h2>
              </div>
            </div>
          )}
        </Card>
      </section>
    </React.Fragment>
  );
};
export default LivePage;

function MeetingView() {
  const [joined, setJoined] = useState<any>(null);
  const [streaming_started, setStreamingStarted] = useState(false);
  //Get the method which will be used to join the meeting.
  //We will also get the participants list to display all participants
  const { join, participants } = useMeeting({
    //callback for when meeting is joined successfully
    onMeetingJoined: () => {
      console.log("::: ", participants);
      setJoined("JOINED");
    },
    onLiveStreamStarted: () => {
      setStreamingStarted(true);
    },
  });
  const joinMeeting = () => {
    setJoined("JOINING");
    join();
  };

  return (
    <React.Fragment>
      <div>
        {joined && joined == "JOINED" ? (
          <Grid container spacing={2}>
            {[...participants.keys()].map(
              (participantId: any, index: number) => (
                <Grid item xs={12} sm={6} md={4} key={index + participantId}>
                  <ParticipantView
                    participantId={participantId}
                    key={participantId}
                  />
                </Grid>
              )
            )}
          </Grid>
        ) : joined && joined === "JOINING" ? (
          <div className="pxy20">
            <div className="text-center pb10">Joining the meeting...</div>
            <LinearProgress />
          </div>
        ) : (
          <div className="pxy20">
            <button className="btn btn-small btn-primary" onClick={joinMeeting}>
              Join the meeting
            </button>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

function ParticipantView(props: any) {
  const micRef: any = useRef(null);
  const { webcamStream, micStream, webcamOn, micOn, isLocal, displayName } =
    useParticipant(props.participantId);

  const videoStream = useMemo(() => {
    if (webcamOn && webcamStream) {
      const mediaStream = new MediaStream();
      mediaStream.addTrack(webcamStream.track);
      return mediaStream;
    }
  }, [webcamStream, webcamOn]);

  useEffect(() => {
    if (micRef.current) {
      if (micOn && micStream) {
        const mediaStream: any = new MediaStream();
        mediaStream.addTrack(micStream.track);

        micRef.current.srcObject = mediaStream;
        micRef.current
          .play()
          .catch((error: any) =>
            console.error("videoElem.current.play() failed", error)
          );
      } else {
        micRef.current.srcObject = null;
      }
    }
  }, [micStream, micOn]);

  return (
    <div className="video-port">
      <span className="mode-bar-speaker">{props.participantId}</span>
      <audio ref={micRef} autoPlay playsInline muted={isLocal} />
      {webcamOn && (
        <ReactPlayer
          playsinline // very very imp prop
          pip={false}
          light={false}
          controls={false}
          muted={true}
          playing={true}
          url={videoStream}
          height={"auto"}
          width={"100%"}
          onError={(err) => {
            console.log(err, "participant video error");
          }}
        />
      )}
    </div>
  );
}
