import React from "react";
import { Routes, Route } from "react-router-dom";
import MembershipHome from "./MembershipHome";
import ClearanceDetail from "./ClearanceDetail";

const Membership = () => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<MembershipHome />} />
        <Route path="/" element={<MembershipHome />} />
        <Route path="/p/:cId" element={<ClearanceDetail />} />
      </Routes>
    </React.Fragment>
  );
};

export default Membership;
