import React from "react";
import { Route, Routes } from "react-router-dom";
import * as processHtml from "../../../services/processHtml";
import AboutHome from "./AboutHome";
import Heritage from "./Heritage";
import AboutPage from "./AboutPage";

const About = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<AboutPage />} />
        <Route path="/" element={<AboutPage />} />
        <Route path="/:url" element={<AboutPage />} />
      </Routes>
    </React.Fragment>
  );
};

export default About;
