import React from "react";
import { Link, useParams } from "react-router-dom";
import { Breadcrumbs, Card, Grid, Stack, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import { DefaultEditor } from "react-simple-wysiwyg";
import HttpService from "../../../services/HttpService";
import CustomModal from "../../templates/CustomModal";
import dayjs, { Dayjs } from "dayjs";
//import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

const EditCase = () => {
  const params = useParams();
  const isParam = params.mId ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [cases, setCase] = React.useState<any>({ designation: "0" });

  const [title, setTitle] = React.useState<any>("");
  const [plaintiff, setPlaintiff] = React.useState<any>("");
  const [filed_date, setFiledDate] = React.useState<any>("");
  const [assigned_date, setAssignedDate] = React.useState<any>("");
  const [description, setDesc] = React.useState<any>("");
  const [defender, setDefender] = React.useState<any>("");
  let [id, setId] = React.useState<any>(0);

  React.useEffect(() => {
    getcase(params.mId);
  }, [params?.mId]);

  const getcase = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ id: id }, "case_details")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setCase(result.data);
            setTitle(result.data.title);
            setPlaintiff(result.data.plaintiff);
            setDefender(result.data.defender);
            setFiledDate(new Date(result.data.filed_date * 1000).toUTCString());
            setAssignedDate(
              new Date(result.data.assigned_date * 1000).toUTCString()
            );
            setDesc(result.data.description);
            setId(result.data.id);
            setPreview(process.env.REACT_APP_SERVER_ROOT + result.data.picture);
          } else {
            setCase({});
          }
        },
        (error) => {
          setCase({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const onHtmlChange = (e: any) => {
    setDesc(e.target.value);
  };

  const handleInputChange = React.useCallback(
    (e: any) => {
      console.log(e.target);
      const name = e.target.title;
      setCase({ ...cases, [name]: e.target.value });
    },
    [cases]
  );

  const [file, setFile] = React.useState<any>(null);
  const [new_file, setNewFile] = React.useState<any>(0);
  const [preview_image, setPreview] = React.useState<any>(null);
  const handleFileChange = (e: any) => {
    const formData = new FormData();
    let fname = e.target.title;
    let flx = e.target.files[0];
    formData.append("file", flx);
    console.log(flx, formData);
    setFile(flx);
    console.log(flx);
    setPreview(URL.createObjectURL(flx)); // Would see a path?
    setNewFile(1);
  };
  const closeModal = () => {
    setModal({ ...modal, onopen: false });
  };
  const [modal, setModal] = React.useState<any>({
    onopen: false,
    onclose: closeModal,
    title: "New case",
  });

  const handleSubmit = () => {
    console.log("SUBMITTING");
    setLoading(true);
    setLoaded(false);

    const formData = new FormData();
    formData.append("page", "admin");
    formData.append("id", id);
    formData.append("defender", defender);
    formData.append("title", title);
    formData.append("plaintiff", plaintiff);
    formData.append("filed_date", filed_date);
    formData.append("assigned_date", assigned_date);
    formData.append("banner", file);
    formData.append("description", description);
    console.log(formData);
    HttpService.postFormHeader("edit_case", formData)
      .then((resp) => {
        console.log(resp);
        const severity =
          resp.status === 1
            ? "success"
            : resp.status === 0
            ? "error"
            : resp.status === 44
            ? "error"
            : "info";
        setModal({
          ...modal,
          onopen: true,
          message: resp.message,
          severity: severity,
        });
        if (resp.status === 1) {
        }
      })
      .catch((err) => {
        console.log(err);
        setModal({
          ...modal,
          onopen: true,
          severity: "error",
          message: err.message,
        });
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <Link to="/admin/cases">Cases</Link>
            <span>Edit {cases?.title}</span>
          </Breadcrumbs>
          <h1>Edit {cases?.title}</h1>
        </div>
        <section className="page-detail-container">
          <div className="py30 px20">
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={12}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <label>Case Title</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    placeholder={"Case Title"}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Plaintiff</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="plaintiff"
                    value={plaintiff}
                    onChange={(e) => setPlaintiff(e.target.value)}
                    placeholder={"Plaintiff "}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <label>Defender</label>
                  <input
                    type="text"
                    className="input-form-control"
                    name="defender"
                    value={defender}
                    onChange={(e) => setDefender(e.target.value)}
                    placeholder={"Defender "}
                  />
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed" : ""}`}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                      <DateTimePicker
                        label="Filed Date"
                        value={filed_date}
                        onChange={(e) => setFiledDate(e?.toISOString())}
                        renderInput={(params: any) => (
                          <TextField className="flex spacer" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <div className={`input mb0 spacer ${loading ? "iconed " : ""}`}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Stack spacing={3} sx={{ width: "100%" }}>
                      <DateTimePicker
                        label="Assigned Date"
                        value={assigned_date}
                        onChange={(e) => setAssignedDate(e?.toISOString())}
                        renderInput={(params: any) => (
                          <TextField className="flex spacer" {...params} />
                        )}
                      />
                    </Stack>
                  </LocalizationProvider>
                </div>
              </Grid>
            </Grid>

            <div className="banner-section">
              {preview_image && (
                <div className="image_preview">
                  <img className="" src={preview_image} alt="preview Image" />
                </div>
              )}
              <div
                className={
                  loading
                    ? " input mb0 iconed mt20 pt10"
                    : " input mb0 mt20 pt10"
                }
              >
                <label>Attach case's Banner</label>
                <input
                  type="file"
                  className="form-control"
                  name="file"
                  onChange={handleFileChange}
                  placeholder={"picture"}
                />
              </div>
            </div>

            <div className="mb10">
              <DefaultEditor
                className="form-control"
                placeholder="Case  description"
                value={description}
                onChange={onHtmlChange}
              />
            </div>
            <Button
              type="submit"
              size="large"
              variant="contained"
              disabled={loading}
              onClick={handleSubmit}
            >
              {loading ? "Working..." : " Edit case"}
            </Button>
          </div>
        </section>
      </Card>
      {modal.onopen && <CustomModal data={modal} />}
    </div>
  );
};

export default EditCase;
