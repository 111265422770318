import React from "react";
import HttpService from "../services/HttpService";

const useFetchMembers = () => {
  const [members, setMembers] = React.useState<any[]>([]);
  const [loading_members, setLoading] = React.useState(false);
  const [loaded_members, setLoaded] = React.useState(false);

  React.useEffect(() => {
    fetch_case();
  }, []);

  const fetch_case = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({}, "all_members")
      .then(
        (res) => {
          if (res.status === 1) {
            setMembers(res.data);
          }
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return {
    members,
    loading_members,
    loaded_members,
  };
};

export default useFetchMembers;
