import React from "react";
import { Link, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import SeoModule from "../../../services/SeoModule";
import useFetchPage from "../../../hooks/useFetchPage";
import * as processHtml from "../../../services/processHtml";
import { LinearProgress } from "@mui/material";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import PageIntro from "../PageIntro/PageIntro";
import SideLinks from "../../templates/SideLinks";
import HttpService from "../../../services/HttpService";

const AboutPage = () => {
  const params: any = useParams();
  console.log(params);
  const { decodeHtml, truncateWord } = processHtml;
  const [loading_page, setLoading] = React.useState(false);
  const [loaded_page, setLoaded] = React.useState(false);
  const [page, setPage] = React.useState<any>(null);
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    getPage(params?.url);
  }, [params]);

  const getPage = (url: any) => {
    let pgr = url ? url : "about";

    console.log("url::", pgr);
    setLoading(true);
    setLoaded(false);
    HttpService.post({ slug: pgr }, "fetch_page")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setPage(result.data);
          }
        },
        (error) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  const links: any[] = [
    { path: "/about", title: "Introduction" },
    { path: "/about/history", title: "History" },
    { path: "/about/quarters", title: "Quarters" },
    { path: "/about/heritage", title: "Heritage" },
    { path: "/about/leaders", title: "Leaders" },
    { path: "/about/ebo-ani", title: "Ichie Eboani" },
    { path: "/about/committees", title: "Committees" },
  ];
  return (
    <React.Fragment>
      <SeoModule
        title={page?.title}
        description="Fintech: banking, crypto, investments"
        name="Reva"
        page_url={process.env.REACT_APP_SERVER_DOMAIN}
        page_image={process.env.REACT_APP_SERVER_DOMAIN + "images/logo.png"}
        type="Crypto & Investment"
      />
      <section className="page-main">
        <div className="home-overlay"></div>
        <Header />
      </section>
      <section className="container py20">
        <Card sx={{ borderRadius: "0" }}>
          <div className="about-container">
            <SideLinks
              links={links}
              loading={loading_page}
              loaded={loaded_page}
            />
            <div className="main-about-content">
              {loading_page && <LinearProgress />}
              {loaded_page && (
                <>
                  <h1>{page?.title || params.url}</h1>
                  <div
                    className="mt10"
                    dangerouslySetInnerHTML={{
                      __html: decodeHtml(page?.description),
                    }}
                  ></div>
                </>
              )}
            </div>
          </div>
        </Card>
      </section>

      <Footer />
    </React.Fragment>
  );
};

export default AboutPage;
