import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import {
  Checkbox,
  DialogActions,
  Divider,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { AccountBox } from "@mui/icons-material";

const TransitionUp = React.forwardRef(function Transition(
  props: any,
  ref: any
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SelectOptionsModal(props: any) {
  const modal = props.data;
  const {
    modal_title,
    onopen,
    onclose,
    list,
    list_name,
    select_count,
    selected_list,
    list_item_title,
    feed_back,
  } = props.data;
  console.log(modal);

  let [selected_members, setSelected] = React.useState<any[]>([]);
  React.useEffect(() => {
    setSelected(selected_list);
  }, []);
  const fill_member = React.useCallback(
    (item: any) => {
      //let obj = { id: item.id, name: item.name };
      const idx = selected_members.indexOf(item);
      console.log("idx::", idx);
      if (idx < 0) {
        let temp = [...selected_members];
        temp.push(item);
        setSelected(temp);
      } else {
        selected_members.splice(idx, 1);
      }
      console.log(selected_members);
    },
    [selected_members]
  );
  let outer = () => {
    feed_back(selected_members);
    onclose();
  };
  return (
    <>
      <Dialog
        open={onopen}
        aria-labelledby={"Me"}
        id={"md-"}
        TransitionComponent={TransitionUp}
      >
        <div className={`head-area bg-info`}>
          <i className={`fas fa-info-circle`}></i>
        </div>
        <div className="flex px10 py10">
          <input
            type="search"
            className="input-form-control py5-i"
            placeholder={`Search ${list_name}...`}
          />
        </div>
        <Divider />
        <DialogContent sx={{ p: "0" }}>
          {/*       {JSON.stringify(selected_members)} */}
          <div className="alert-container">
            <div className={`message-area-x info-color`}>
              <div className="flex flex-col">
                {list &&
                  list.map((item: any, index: number) => (
                    <ListItem
                      key={item.id}
                      divider
                      disablePadding
                      secondaryAction={
                        /* 
                        select_count > 1 ? (
                          <Check />
                        ) : (
                          <CheckCircleOutlineOutlined />
                        )*/
                        <Checkbox
                          edge="start"
                          checked={selected_members.indexOf(item) !== -1}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ "aria-labelledby": item.id }}
                        />
                      }
                    >
                      <ListItemButton onClick={() => fill_member(item)} dense>
                        <ListItemIcon>
                          <AccountBox />
                        </ListItemIcon>

                        <ListItemText>
                          <h3>
                            [{selected_members.indexOf(item)}]
                            {item[list_item_title]}
                          </h3>
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  ))}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", flexDirection: "column", p: "0" }}
        >
          <div className="button-area">
            <Button
              fullWidth
              size="large"
              onClick={outer}
              sx={{ paddingY: "13px", color: "#888" }}
            >
              <span className="px10">EXIT</span>
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
