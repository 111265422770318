import React from "react";
import { Routes, Route } from "react-router-dom";
import EventCategory from "./EventCategory";
import EventDetail from "./EventDetail";
import EventList from "./EventList";
import Footer from "../../Footer/Footer";
const Events = () => {
  return (
    <React.Fragment>
      <section className="bg-diff">
        <Routes>
          <Route path="" element={<EventList />} />
          <Route path="/" element={<EventList />} />
          <Route path="/e/:url" element={<EventDetail />} />
          <Route path="/:catUrl" element={<EventCategory />} />
        </Routes>
      </section>
      <Footer/>
    </React.Fragment>
  );
};

export default Events;
