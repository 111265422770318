import React from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../../Footer/Footer";
import LiveList from "./LiveList";
import LivePage from "./LivePage";
import LiveCategory from "./LiveCategory";
const Live = () => {
  return (
    <React.Fragment>
      <section className="bg-diff">
        <Routes>
          <Route path="" element={<LiveList />} />
          <Route path="/" element={<LiveList />} />
          <Route path="/stream/:lId" element={<LivePage />} />
          <Route path="/:catUrl" element={<LiveCategory />} />
        </Routes>
      </section>
      <Footer />
    </React.Fragment>
  );
};

export default Live;
