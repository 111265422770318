import {
  Breadcrumbs,
  Button,
  Card,
  Chip,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import HttpService from "../../../services/HttpService";
import AuthService from "../../../services/AuthService";
import Currency from "../../../pipes/Currency";
const usr = AuthService.getCurrentUser();
const ClearanceList = () => {
  console.log("clearance list loads....");
  const [clearance, setClearance] = React.useState<any>(null);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  React.useEffect(() => {
    getclearance();
    console.log(usr);
  }, []);
  const getclearance = () => {
    setLoading(true);
    setLoaded(false);
    let dta = { uid: usr?.id };
    HttpService.postHeader("show_clearance", dta)
      .then(
        (res) => {
          if (res.status === 1) {
            let rx = res.data;
            setClearance(rx);
            console.log("clr::", rx);
          }
        },
        (err) => {}
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return (
    <React.Fragment>
      <section className="p0">
        {clearance?.all.map((item: any, index: number) => (
          <ListItem
            disablePadding
            divider
            key={item.title}
            secondaryAction={
              <span>
                <Currency value={item.amount} />
              </span>
            }
          >
            <ListItemButton>
              <ListItemText
                primary={
                  <h3>
                    {item.title}
                    <sup>
                      <span className="chip ucap mx5 inline-block txt-xsm">
                        {item.clearance_type}
                      </span>
                    </sup>
                  </h3>
                }
              ></ListItemText>
            </ListItemButton>
          </ListItem>
        ))}
        {/**/}
      </section>
    </React.Fragment>
  );
};

export default ClearanceList;
