import { Breadcrumbs, Card } from "@mui/material";
import React from "react";
import { Link, useParams } from "react-router-dom";
import HttpService from "../../../../services/HttpService";

const KindredDetails = (props: any) => {
  const params = useParams();
  const isParam = params.kid ? true : false;
  let [loading, setLoading] = React.useState<any>(false);
  let [loaded, setLoaded] = React.useState<any>(false);
  let [kindred, setKindred] = React.useState<any>({});

  const [name, setName] = React.useState<any>("");
  const [description, setDesc] = React.useState<any>("");
  let [id, setId] = React.useState<any>(0);

  React.useEffect(() => {
    getKindred(params.kid);
  }, [params?.kid]);

  const getKindred = (id: any) => {
    setLoading(true);
    setLoaded(false);
    HttpService.post({ id: id }, "kindred_details")
      .then(
        (result) => {
          setLoading(false);
          console.log(result);
          if (result.status === 1) {
            setKindred(result.data);
            setName(result.data.name);
            setDesc(result.data.description);
            setId(result.data.id);
          } else {
            setKindred({});
          }
        },
        (error) => {
          setKindred({});
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      }); //fetch
  }; //doAjax

  return (
    <React.Fragment>
      <div className="account-page-container">
        <Card>
          <div className="page-topper">
            <Breadcrumbs>
              <Link to="/admin">Dashboard</Link>
              <Link to="/admin/about/kindreds">Kindreds</Link>
              <span> {kindred?.name}</span>
            </Breadcrumbs>
            <h1> {kindred?.name}</h1>
          </div>
          <section className="page-detail-container">
            <div className="py30 px20"></div>
          </section>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default KindredDetails;
