import React from "react";
import { Routes, Route } from "react-router-dom";
import NonIndigenesList from "./NonIndigenesList";
import NonIndigenesDetail from "./NonIndigenesDetail";
import NonIndigeneNew from "./NonIndigeneNew";

const NonIndigenes = (props: any) => {
  return (
    <React.Fragment>
      <Routes>
        <Route path="" element={<NonIndigenesList />} />
        <Route path="/" element={<NonIndigenesList />} />
        <Route path="/a/new" element={<NonIndigeneNew />} />
        <Route path="/p/:mId" element={<NonIndigenesDetail />} />
      </Routes>
    </React.Fragment>
  );
};

export default NonIndigenes;
