import * as React from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import AuthService from "../../services/AuthService";
import AdminHeader from "./AdminHeader";
import AdminSidePanel from "./AdminSidePanel";
import Four0FourPane from "../public/Four0Four/Four0FourPane";
import Dashboard from "./Dashboard/Dashboard";
import Messages from "./Messages/Messages";
import Settings from "./Settings/Settings";
import { CssBaseline, LinearProgress } from "@mui/material";
import Members from "./Members/Members";
import Events from "./Events/Events";
import Meetings from "./Meetings/Meetings";
import Transactions from "./Transactions/Transactions";
import About from "./About/About";
import Levies from "./Levies/Levies";
import Penalties from "./Penalties/Penalties";
import NonIndigenes from "./NonIndigenes/NonIndigenes";
import Cases from "./Cases/Cases";
import Homepage from "./Homepage/Homepage";
import "../dashboard.css";
import "../skeleton.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

export default function Admin() {
  console.log("Admin page Renders");
  const [user_logged, setUserLogged] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [to_verify, setToVerify] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [loaded, setLoaded] = React.useState(false);
  const [resending, setResending] = React.useState(false);
  const [user_verified, setUserVerified] = React.useState(false);
  const [verify_data, setVerifyData] = React.useState<any>({});
  const [checked_session, setChecked] = React.useState<boolean>(false);
  const [session_checked, setSessionChecked] = React.useState(false);
  const usr = AuthService.getCurrentUser();
  let navigate = useNavigate();
  let location = useLocation();
  const [hide_sections, setHideSections] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (location?.state?.hide_sections) {
      setHideSections(true);
    }
  }, [location]);
  React.useEffect(() => {
    AuthService.checkSession().then(
      (res) => {
        setChecked(true);
        console.log("SESSION CHECK RESULT:: ", res);
        console.log("USR:: ", usr);
        if (!res.status || res.status === false) {
          AuthService.logout();
          setRedirect(true);
          setUserVerified(false);
          return navigate("/login");
        } else if (res.status && usr.is_verified === 0) {
          setToVerify(true);
          setVerifyData({ ...verify_data, user: usr.email });
          return navigate("/verify-account", { state: verify_data });
          setUserVerified(false);
        } else if (res.status && usr.is_verified === 1) {
          setUserVerified(true);
        } /**/
        if (res.status) {
          setUserLogged(true);
          setUserVerified(true);
        }
        setSessionChecked(true);
      },
      (error) => {
        setSessionChecked(true);
        AuthService.logout();
        return navigate("/login");
      }
    );
  }, []);

  const goVerify = () => {
    return navigate("/verify-account");
  };
  const doLogout = () => {
    AuthService.logout();
    setTimeout(() => {
      console.log("Session Cleared...");
      return navigate("/");
    }, 300);
  };

  //const [isLogged, setIsLogged] = React.useState(logx);
  const [cur_slug, setSlug] = React.useState<any | string>(null);

  const page = useLocation()["pathname"];
  const pgarr = page.split("/");
  console.log(page, pgarr);
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (pgarr.length == 4) {
      setSlug(pgarr[3]);
    }
  }, []);

  const [togged, setTogged] = React.useState<boolean>(false);
  const togNav = () => {
    setTogged(!togged);
  };

  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          flexGrow: "1 1",
          width: "100%",
          minHeight: "100vh",
          padding: "0 !important",
          backgroundColor: "#f5f5f5",
        }}
      >
        <CssBaseline />
        <AdminHeader
          open={open}
          toggleDrawer={toggleDrawer}
          DrawerHeader={DrawerHeader}
          doLogout={doLogout}
          usr={usr}
          togNav={togNav}
          location={location}
        />
        {session_checked && user_logged && (
          <>
            <AdminSidePanel
              onopen={open}
              onclose={handleDrawerClose}
              DrawerHeader={DrawerHeader}
              doLogout={doLogout}
            />
            <main className="dashboard-cover">
              <DrawerHeader />
              <Routes>
                <Route path="" element={<Dashboard />}></Route>
                <Route path="/" element={<Dashboard />}></Route>
                <Route path="/dashboard" element={<Dashboard />}></Route>
                <Route path="/homepage" element={<Homepage />}></Route>
                <Route path="/about/*" element={<About />}></Route>
                <Route path="/events/*" element={<Events />}></Route>
                <Route path="/levies/*" element={<Levies />}></Route>
                <Route path="/penalties/*" element={<Penalties />}></Route>
                <Route
                  path="/non-indigenes/*"
                  element={<NonIndigenes />}
                ></Route>
                <Route path="/meetings/*" element={<Meetings />}></Route>
                <Route
                  path="/transactions/*"
                  element={<Transactions />}
                ></Route>
                <Route path="/messages/*" element={<Messages />}></Route>
                <Route path="/members/*" element={<Members />}></Route>
                <Route path="/cases/*" element={<Cases />}></Route>
                <Route
                  path="/settings/*"
                  element={<Settings user={usr} />}
                ></Route>
                <Route path="*" element={<Four0FourPane home="/account" />} />
              </Routes>
            </main>
          </>
        )}

        {session_checked && !user_logged && (
          <div className="full-main-height inline-flex flex-col align-items-center pxy20">
            <span className="inline-block boldest txt-lg">NOT LOGGED IN</span>
          </div>
        )}

        {!session_checked && (
          <div className="full-main-height inline-flex flex-col align-items-center pxy20">
            <Box sx={{ width: "30%" }}>
              <LinearProgress />
            </Box>
            <div className="py20">
              <h3>Loading... </h3>
            </div>
          </div>
        )}
      </Box>
    </React.Fragment>
  );
}
