import { Breadcrumbs, Card } from "@mui/material";
import { Link } from "react-router-dom";

const MeetingDetail = () => {
  return (
    <div className="account-page-container">
      <Card>
        <div className="page-topper">
          <Breadcrumbs>
            <Link to="/admin">Dashboard</Link>
            <span>Members</span>
          </Breadcrumbs>
          <h1> All Members</h1>
        </div>
        <section className="page-detail-container"></section>
      </Card>
    </div>
  );
};

export default MeetingDetail;
