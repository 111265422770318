import React from "react";
import HttpService from "../services/HttpService";

const useFetchQuaters = () => {
  const [quaters, setQuaters] = React.useState<any[]>([]);
  const [loading_quaters, setLoading] = React.useState(false);
  const [loaded_quaters, setLoaded] = React.useState(false);

  React.useEffect(() => {
    fetch_kindred();
  }, []);

  const fetch_kindred = () => {
    setLoading(true);
    setLoaded(false);
    HttpService.postHeader("list_quarters", {})
      .then(
        (res) => {
          if (res.status === 1) {
            setQuaters(res.data);
          }
        },
        (err) => {
          console.log(err);
        }
      )
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  return { quaters, loading_quaters, loaded_quaters };
};

export default useFetchQuaters;
