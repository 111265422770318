const decodeHtml = (html: string) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

function padTime(t: any) {
  return t < 10 ? "0" + t : t;
}

const secondsToTime = (_seconds: any) => {
  //if (typeof _seconds !== "number" || _seconds < 0)
  //return "00:00:00";

  var hours = Math.floor(_seconds / 3600),
    minutes = Math.floor((_seconds % 3600) / 60),
    seconds = Math.floor(_seconds % 60);

  return padTime(hours) + ":" + padTime(minutes) + ":" + padTime(seconds);
};

const truncateWord = (
  str: string,
  n: number,
  useWordBoundary: boolean = true
) => {
  if (str.length <= n) {
    return str;
  }
  const subString = str.slice(0, n - 1); // the original check
  return (
    (useWordBoundary
      ? subString.slice(0, subString.lastIndexOf(" "))
      : subString) + "&hellip;"
  );
};

export { truncateWord, decodeHtml, secondsToTime };
